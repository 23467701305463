/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from "styled-components";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isCorrect: boolean;
  rounded: boolean;
  multiline: boolean;
}

const inputRoundVariations = {
  rounded: css`
    border-radius: 18px;
  `,
  block: css`
    border-radius: 8px;
  `,
};

export const Label = styled.label`
  display: flex;
  margin-bottom: 10px;
  color: var(--title-highlight);
`;

export const Container = styled.div<ContainerProps>`
  background: var(--white);
  color: var(--text);
  border: 2px solid var(--purple);
  padding: 0 0 0 16px;
  width: 100%;

  display: flex;
  align-items: center;

  ${(props) =>
    props.rounded ? inputRoundVariations.rounded : inputRoundVariations.block}

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isFocused &&
    css`
      border-color: var(--orange);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--orange);
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  ${(props) =>
    props.isCorrect &&
    css`
      border-color: var(--green);
      color: var(--green);
    `}

    ${(props) =>
    !props.multiline &&
    css`
      height: 41px;

      input {
        color: var(--text);
        flex: 1;
        background: transparent;
        border: 0;
        width: 100%;

        &::placeholder {
          color: var(--gray-line);
        }
      }
    `}

    ${(props) =>
    !props.multiline &&
    css`
      input {
        color: var(--text);
        flex: 1;
        background: transparent;
        border: 0;
        width: 100%;

        &::placeholder {
          color: var(--gray-line);
        }
      }
    `}

    ${(props) =>
    props.multiline &&
    css`
      padding: 0;

      textarea {
        color: var(--text);
        flex: 1;
        background: transparent;
        border: 0;
        width: 100%;

        &::placeholder {
          color: var(--gray-line);
        }
      }
    `}
  
  label {
    padding-bottom: 15px;
    color: var(--title-higlight);
  }

  svg {
    margin-right: 10px;
  }
`;

export const Error = styled.label`
  font-family: "Inter", sans-serif;
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: var(--red);
  display: flex;
`;
