/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from "styled-components";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isCorrect: boolean;
}

export const Container = styled.div<ContainerProps>`
  .react-select__control {
    background: var(--white);
    color: var(--text);
    border: 2px solid var(--purple);
    padding: 0 0 0 5px;
    width: 100%;
    height: 30px;
    border-radius: 8px;
    min-width: 200px;
    @media (max-width: 1200px) {
      min-width: 150px;
    }
  }

  .react-select__placeholder {
    color: var(--gray-line);
  }

  .react-select__indicator-separator {
    height: 25px;
    background-color: var(--purple);
  }

  .react-select__indicator {
    color: var(--purple);
  }

  .react-select__control {
    margin: 2px;
  }

  .react-select__single-value {
    font-size: 18px;
    font-weight: 400;
    color: var(--text);
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      .react-select__control {
        border-color: #c53030;
      }
    `}

  ${(props) =>
    props.isFocused &&
    css`
      .react-select__control {
        border-color: #d65b15 !important;
      }
    `}

  ${(props) =>
    props.isCorrect &&
    css`
      .react-select__control {
        border-color: green !important;
        color: green;
      }
    `}
`;

export const Error = styled.label`
  height: 20px;
  font-size: 15px;
  margin-bottom: 3px;
  color: #c53030;
  display: flex;
`;

export const Label = styled.label`
  font-size: 16px;
`;
