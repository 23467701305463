/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";

import * as Yup from "yup";

import {
  Avatar,
  Box,
  Container,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LockOutlined } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";

import { ItemText } from "../../../itemText";
import theme from "../../../../theme";
import { ArrayFilters } from "../../../../interfaces/filterSearch";

interface AccountsProps {
  aside?: any;
  tipocartao?: any;
}

const PorTipoCartao: React.FC<AccountsProps> = ({ aside, tipocartao }) => {
  const dadosget = localStorage.getItem("tipocartao");
  const [selectedItem, setSelectedItem] = React.useState("todos");
  const [selectedStatus, setSelectedStatus] = React.useState(dadosget);

  const handleStatus = (item: any, name: any) => {
    tipocartao(
      item ? { value: item, label: name, fOrigem: "card_type" } : "null"
    );
  };

  const handleItemClick = useCallback(async (item: any, name: any) => {
    localStorage.removeItem("tipocartao");
    setSelectedItem(item);

    handleStatus(item, name);
    localStorage.setItem("tipocartao", `${item}`);
  }, []);

  const Dados = [
    { value: "todos", label: "Todos" },
    { value: "qr_code", label: "QR CODE" },
    { value: "P", label: "Portador" },
    { value: "D", label: "Identificado" },
  ];

  useEffect(() => {
    if (selectedStatus !== null) {
      setSelectedItem(selectedStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    setSelectedStatus(dadosget);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            minWidth: "250px",
            maxWidth: "250px",
          }}
        >
          <Stack>
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <ItemText
                  primary="Por Tipo de Cartão"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontSize: "16px",
                    textAlign: "left-start",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
          <Stack>
            {Dados.map((item) => (
              <ListItemButton
                key={item.value}
                component={LoadingButton}
                onClick={() => handleItemClick(item.value, item?.label)}
                sx={{
                  backgroundColor:
                    selectedItem === item.value ? "#482A73" : "white",
                  borderRadius: "10px",
                }}
              >
                <ItemText
                  primary={item?.label}
                  hideSecondary
                  primarySx={{
                    color:
                      selectedItem === item.value
                        ? "white"
                        : theme.palette.secondary.main,
                    fontWeight: "bold",
                    fontSize: "12px",
                    backgroundColor:
                      selectedItem === item.value ? "#482A73" : "white",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
            ))}
          </Stack>
        </Box>
      </List>
    </Box>
  );
};

export default PorTipoCartao;
