/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { ListSubheader, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { Accounts } from "../homeComponents/accounts/accountsView";
import { Ticketing } from "../homeComponents/ticketing/ticketingView";
import { ItemText } from "../itemText";
import theme from "../../theme";
import Filter from "../filtro";
import { Core } from "../homeComponents/core/coreView";
import { SettingsView } from "../homeComponents/settings/settingsView";

type Anchor = "left" | "right";

interface TiposdeFiltro {
  tipofiltro?: string;
  filtroNaparece?: boolean;
  homeNaparece?: boolean;
  datainicial?: any;
  datafinal?: any;
  tipoStatus?: any;
  tipoRecKonduto?: any;
  tipopagamento?: any;
  tipoestocduplicada?: any;
  tipocartao?: any;
  porOrigem?: any;
  isDark?: boolean;
  porLogs?: any;
  porGateway?: any;
  dadosFiltros?: any;
}

const SideBar: React.FC<TiposdeFiltro> = ({
  tipofiltro,
  filtroNaparece,
  homeNaparece,
  datainicial,
  datafinal,
  tipoStatus,
  tipoRecKonduto,
  tipopagamento,
  tipoestocduplicada,
  tipocartao,
  porOrigem,
  isDark,
  porGateway,
  dadosFiltros,
  porLogs,
}) => {
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const group = localStorage.getItem("@UserGroup:group");
  const groupParse = JSON.parse(group);

  const receberDadosPorpedidoIni = (dados: any) => {
    datainicial(dados);
  };
  const receberDadosPorpedidoFnl = (dados: any) => {
    datafinal(dados);
  };
  const receberDadosStatus = (dados: any) => {
    tipoStatus(dados);
  };
  const receberDadosRecKonduto = (dados: any) => {
    tipoRecKonduto(dados);
  };

  const receberDadosTipoPagamento = (dados: any) => {
    tipopagamento(dados);
  };

  const receberDadosTipoEstocagem = (dados: any) => {
    tipoestocduplicada(dados);
  };

  const receberDadosTipoCartao = (dados: any) => {
    tipocartao(dados);
  };

  const receberDadosPorOrigem = (dados: any) => {
    porOrigem(dados);
  };

  const receberDadosPorLogs = (dados: any) => {
    porLogs(dados);
  };

  const receberDadosPorGateway = (dados: any) => {
    porGateway(dados);
  };
  const receberDadosFiltros = (dados: any) => {
    dadosFiltros(dados);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const navbar = (anchor: "left") => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Box
          sx={{
            display: "flex",
            marginLeft: 2,
            marginRight: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "200px",
              borderRadius: 4,
              backgroundColor: theme.palette.primary.main,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Stack>
              <ListSubheader
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 10,
                  textAlign: "center",
                }}
              >
                <Box>
                  <Link to="/">
                    <ItemText
                      primary="Home"
                      hideSecondary
                      primarySx={{
                        color: "white",
                        fontSize: "20px",
                      }}
                    />
                  </Link>
                </Box>
              </ListSubheader>
            </Stack>
          </Box>
        </Box>
      </List>
      {/* <List>
        <Accounts />
      </List> */}
      <Divider />
      <List>
        {groupParse?.map((dados: any) =>
          dados?.name === "Marketing" ? (
            <Core />
          ) : dados?.name === "Chargeback" ? (
            <Ticketing />
          ) : (
            <>
              <Core />
              <Ticketing />
              <SettingsView />
            </>
          )
        )}
      </List>
    </Box>
  );

  const filter = (anchor: "right") => (
    <Box sx={{ width: "auto" }} role="presentation">
      <List>
        <Filter
          datainicial={receberDadosPorpedidoIni}
          datafinal={receberDadosPorpedidoFnl}
          tipofiltro={tipofiltro}
          tipoStatus={receberDadosStatus}
          tipoRecKonduto={receberDadosRecKonduto}
          tipopagamento={receberDadosTipoPagamento}
          tipoestocduplicada={receberDadosTipoEstocagem}
          tipocartao={receberDadosTipoCartao}
          porOrigem={receberDadosPorOrigem}
          porLogs={receberDadosPorLogs}
          porGateway={receberDadosPorGateway}
          dadosFiltros={receberDadosFiltros}
        />
      </List>
      <Divider />
    </Box>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {!homeNaparece && (
        <>
          <Button
            sx={{
              color: "white",
              borderRadius: 4,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              backgroundColor: isDark
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              height: "40px",
              marginRight: "10px",
            }}
            onClick={toggleDrawer("left", true)}
          >
            Menu
          </Button>
          <Drawer
            anchor="left"
            open={state.left}
            onClose={toggleDrawer("left", false)}
          >
            {navbar("left")}
          </Drawer>
        </>
      )}
      {!filtroNaparece && (
        <>
          <Button
            sx={{
              color: "white",
              borderRadius: 4,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              backgroundColor: isDark
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              height: "40px",
            }}
            onClick={toggleDrawer("right", true)}
          >
            Filtros
          </Button>
          <Drawer
            anchor="right"
            open={state.right}
            onClose={toggleDrawer("right", false)}
          >
            {filter("right")}
          </Drawer>
        </>
      )}
    </div>
  );
};

export default SideBar;
