/* eslint-disable react/require-default-props */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import * as Yup from "yup";

import {
  Box,
  Container,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LockOutlined } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";

import { ItemText } from "../../itemText";
import theme from "../../../theme";
import { Link } from "react-router-dom";

interface CoreProps {
  aside?: any;
}
const Core: React.FC<CoreProps> = ({ aside }) => {
  const [selectedItem, setSelectedItem] = React.useState("");

  React.useEffect(() => {
    if (window.location.pathname === "/bannermob") {
      setSelectedItem("bannermob");
    }
    if (window.location.pathname === "/bannerweb") {
      setSelectedItem("bannerweb");
    }
    if (window.location.pathname === "/appversion") {
      setSelectedItem("appversion");
    }
  }, [window.location.pathname]);
  return (
    <Box
      sx={{
        backgroundColor: "var(--aside-background)",
        display: "flex",
        borderRadius: 4,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: aside ? "250px" : "100%",
          padding: "8px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            minWidth: aside ? "100%" : "250px",
          }}
        >
          <Stack>
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <ItemText
                  primary="Core"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
          <Stack>
            <Link to="/bannermob">
              <ListItem
                component={LoadingButton}
                sx={{
                  backgroundColor:
                    selectedItem === "bannermob" ? "#dd6d4b" : "white",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <ItemText
                  primary="Banners Mobile"
                  hideSecondary
                  primarySx={{
                    color:
                      selectedItem === "bannermob"
                        ? "white"
                        : theme.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "14px",
                    backgroundColor:
                      selectedItem === "bannermob" ? "#dd6d4b" : "white",
                    borderRadius: "10px",
                  }}
                />
              </ListItem>
            </Link>
          </Stack>
          <Stack>
            <Link to="/bannerweb">
              <ListItem
                component={LoadingButton}
                sx={{
                  backgroundColor:
                    selectedItem === "bannerweb" ? "#dd6d4b" : "white",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <ItemText
                  primary="Banners Web"
                  hideSecondary
                  primarySx={{
                    color:
                      selectedItem === "bannerweb"
                        ? "white"
                        : theme.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "14px",
                    backgroundColor:
                      selectedItem === "bannerweb" ? "#dd6d4b" : "white",
                    borderRadius: "10px",
                  }}
                />
              </ListItem>
            </Link>
          </Stack>
          <Stack>
            <Link to="/appversion">
              <ListItem
                component={LoadingButton}
                sx={{
                  backgroundColor:
                    selectedItem === "appversion" ? "#dd6d4b" : "white",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <ItemText
                  primary="Versões do App"
                  hideSecondary
                  primarySx={{
                    color:
                      selectedItem === "appversion"
                        ? "white"
                        : theme.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "14px",
                    backgroundColor:
                      selectedItem === "appversion" ? "#dd6d4b" : "white",
                    borderRadius: "10px",
                  }}
                />
              </ListItem>
            </Link>
          </Stack>
        </Box>
      </List>
    </Box>
  );
};

export { Core };
