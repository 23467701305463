/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";

import * as Yup from "yup";

import {
  Avatar,
  Box,
  Container,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form } from "@unform/web";
import { CalendarToday } from "@mui/icons-material";

import { ItemText } from "../../../itemText";
import theme from "../../../../theme";
import { ArrayFilters } from "../../../../interfaces/filterSearch";
import FilterRangeCalendar from "../../../filterRangeCalendar";
import { StyledDiv, StyledIcon } from "./styles";

interface AccountsProps {
  aside?: any;
  datainicial?: any;
  datafinal?: any;
}

const PorDataPedido: React.FC<AccountsProps> = ({
  aside,
  datainicial,
  datafinal,
}) => {
  const dadosget = localStorage.getItem("data");
  const [selectedItem, setSelectedItem] = React.useState("todos");
  const [openValue, setOpenValue] = React.useState(false);
  const [created_at__gte, setCreated_at__gte] = useState(null);
  const [created_at__lt, setCreated_at__lt] = useState(null);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{ created_at__gte }, { created_at__lt }],
  });
  const [selectedStatus, setSelectedStatus] = React.useState(dadosget);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleDatas = (item: any, name: any) => {
    const currentDate = new Date();
    const finalDate = new Date(currentDate);
    if (item?.value === "todos") {
      const datainicio = formatDate(currentDate);
      const datafim = formatDate(finalDate);
      datainicial({ value: `todos`, label: `Todos` });
      datafinal({ value: `todos`, label: `Todos` });
    }
    if (item === "hoje") {
      const today = new Date(currentDate);
      today.setDate(currentDate.getDate() + 1);
      const datainicio = formatDate(currentDate);
      const datafim = formatDate(today);
      datainicial({ value: datainicio, label: name, fOrigem: "date_after" });
      datafinal({ value: datafim, label: name, fOrigem: "date_before" });
    } else if (item === "ultimos_7") {
      const today = new Date(currentDate);
      const firstdate = new Date(currentDate);
      today.setDate(currentDate.getDate() + 1);
      firstdate.setDate(currentDate.getDate() - 7);
      const datainicio = formatDate(firstdate);
      const datafim = formatDate(today);
      datainicial({ value: datainicio, label: name, fOrigem: "date_after" });
      datafinal({ value: datafim, label: name, fOrigem: "date_before" });
    } else if (item === "este_mes") {
      const today = new Date(currentDate);
      const firstdate = new Date(currentDate);
      today.setDate(currentDate.getDate() + 1);
      firstdate.setDate(currentDate.getDate() - 30);
      const datainicio = formatDate(firstdate);
      const datafim = formatDate(today);
      datainicial({ value: datainicio, label: name, fOrigem: "date_after" });
      datafinal({ value: datafim, label: name, fOrigem: "date_before" });
    } else if (item === "este_ano") {
      const today = new Date(currentDate);
      const firstdate = new Date(currentDate);
      today.setDate(currentDate.getDate() + 1);
      firstdate.setDate(currentDate.getDate() - 365);
      const datainicio = formatDate(firstdate);
      const datafim = formatDate(today);
      datainicial({ value: datainicio, label: name, fOrigem: "date_after" });
      datafinal({ value: datafim, label: name, fOrigem: "date_before" });
    } else {
      datainicial({ value: `todos`, label: name, fOrigem: "date_after" });
      datafinal({ value: `todos`, label: name, fOrigem: "date_before" });
    }
  };
  const Dados = [
    { value: "todos", name: "Todos" },
    { value: "hoje", name: "Hoje" },
    { value: "ultimos_7", name: "Últimos 7 dias" },
    { value: "este_mes", name: "Este mês" },
    { value: "este_ano", name: "Este ano" },
  ];
  const handleItemClick = useCallback(async (item: any, name: any) => {
    localStorage.removeItem("data");
    setSelectedItem(item);

    handleDatas(item, name);
    localStorage.setItem("data", `${item}`);
  }, []);

  const handleSubmit = (data: any) => {
    const dataInicial = data?.initialData;
    const dataFinal = data?.finalData;
    const datainifinal = formatDateTime(dataInicial);
    const datafimfinal = formatDateTime(dataFinal);
    console.log(datainifinal);
    console.log(datafimfinal);
    localStorage.removeItem("data");
    setSelectedItem("todos");
    localStorage.setItem("data", `todos`);
    datainicial({
      value: datainifinal,
      label: "Data Personalizada",
      fOrigem: "date_after",
    });
    datafinal({
      value: datafimfinal,
      label: "Data Personalizada",
      fOrigem: "date_before",
    });
  };

  const receberDadosOpen = (dados: boolean) => {
    setOpenValue(dados);
  };

  useEffect(() => {
    if (selectedStatus !== null) {
      setSelectedItem(selectedStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    setSelectedStatus(dadosget);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            minWidth: "250px",
            maxWidth: "250px",
          }}
        >
          <Stack>
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <ItemText
                  primary="Data do Pedido"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontSize: "16px",
                    textAlign: "left-start",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
          <Stack>
            {Dados.map((item) => (
              <ListItemButton
                key={item.value}
                component={LoadingButton}
                onClick={() => handleItemClick(item.value, item.name)}
                sx={{
                  backgroundColor:
                    selectedItem === item.value ? "#482A73" : "white",
                  borderRadius: "10px",
                }}
              >
                <ItemText
                  primary={item.name}
                  hideSecondary
                  primarySx={{
                    color:
                      selectedItem === item.value
                        ? "white"
                        : theme.palette.secondary.main,
                    fontWeight: "bold",
                    fontSize: "12px",
                    backgroundColor:
                      selectedItem === item.value ? "#482A73" : "white",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
            ))}
            <StyledDiv Border={openValue}>
              <StyledIcon onClick={() => setOpenValue(!openValue)}>
                <p>DATA PERSONALIZADA</p>
                <CalendarToday />
              </StyledIcon>
              {openValue && (
                <Form onSubmit={handleSubmit} style={{ marginTop: "2%" }}>
                  <FilterRangeCalendar
                    name="initialData"
                    label="Data Inicial"
                  />
                  <FilterRangeCalendar name="finalData" label="Data Final" />
                  <ListItemButton
                    component={LoadingButton}
                    type="submit"
                    style={{
                      backgroundColor: "var(--purple)",
                      color: "white",
                      width: "100%",
                      borderRadius: "16px",
                      textAlign: "center",
                    }}
                  >
                    <ItemText primary="APLICAR" hideSecondary />
                  </ListItemButton>
                </Form>
              )}
            </StyledDiv>

            {/* <FilterRangeCalendarButton
              value={{
                start: created_at__gte,
                end: created_at__lt,
              }}
              onChange={(e: any) => {
                console.log(e);
                setCreated_at__gte(e.start);
                setCreated_at__lt(e.end);
              }}
              open={receberDadosOpen}
            /> */}
          </Stack>
        </Box>
      </List>
    </Box>
  );
};

export default PorDataPedido;
