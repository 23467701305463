/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiMail, FiPhoneCall, FiKey, FiUser, FiLogOut } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import * as Yup from "yup";

import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ProfileData } from "../../../../../MeuPerfil";
import { useToast } from "../../../../../../hooks/toast";
import getValidationErrors from "../../../../../../utils/getValidationErrors";

import Input from "../../../../../../components/Input";

import AccountsService from "../../../../../../services/accounts";

import { ContainerPrincipal, FileDiv } from "./styles";
import CoreService from "../../../../../../services/core";
import { ItemText } from "../../../../../../components/itemText";
import theme from "../../../../../../theme";
import FileInputSpecial from "../FileInputSpecial";
import Select from "../../../../../../components/Select";
import { CheckBox } from "../../../../../../components/Checkbox";
import ButtonStyled from "../../../../../../components/Button";
import Loader from "../../../../../../components/Loader";
import FilterCalendarDateTime from "../../../../../../components/filterCalendarDateTime";

interface BannerInformationProps {
  dados: any;
  label?: string;
  value?: any;
}

const AppLayoutId: React.FC = () => {
  const formRef = useRef<FormHandles>();
  const pageSize = useMediaQuery("(max-width:800px)");
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dadosVersion, setDadosVersion] = useState(null);
  const [isChecked, setIsChecked] = useState(dadosVersion?.is_active || false);
  const services = { coreServices: new CoreService() };
  const { addToast } = useToast();

  const TipoOptions = [
    { value: "header", label: "Header" },
    { value: "footer", label: "Footer" },
    { value: "middle", label: "Middle" },
    { value: "array", label: "Lista" },
  ];

  const CheckOptions = [
    { value: true, label: "Ativo" },
    { value: false, label: "Inativo" },
  ];

  const [selectedTipo, setSelectedTipo] = useState<any>();
  const [selectedCheck, setSelectedCheck] = useState<any>();

  const getVersionId = useCallback(async () => {
    try {
      const response = await services.coreServices.getAppVersionId(id);
      setIsChecked(response?.data?.is_active);
      setDadosVersion(response?.data);
      const tipoF = TipoOptions.filter(
        (filtros: any) => filtros?.value === response?.data?.banner_type
      );
      const tipoC = CheckOptions.filter(
        (filtros: any) => filtros?.value === response?.data?.is_active
      );
      setSelectedCheck(tipoC);
      setSelectedTipo(tipoF);
    } catch (err: any) {
      const { ...error } = err;
      setLoading(false);

      console.log(error);
      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, [loading]);

  const handleTipoChange = useCallback(
    (selectedOption) => {
      setSelectedTipo(selectedOption);
    },
    [selectedTipo]
  );

  const handleCheckChange = useCallback(
    (selectedOption) => {
      setSelectedCheck(selectedOption);
    },
    [selectedCheck]
  );
  function formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const formData = new FormData();
        // changeLoader(true);

        const result: any = {};

        if (data?.is_active !== undefined) {
          result.is_active = data.is_active;
        }

        if (data?.version !== undefined) {
          result.version = data.version;
        }

        if (data?.release_date !== undefined) {
          result.release_date = formatDateTime(data.release_date);
        }

        if (data?.release_note !== undefined) {
          result.release_note = data.release_note;
        }

        for (const name in result) {
          if (Object.prototype.hasOwnProperty.call(result, name)) {
            formData.append(name, result[name]);
          }
        }

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await services.coreServices.patchAppVersion(
          id,
          formData
        );

        addToast({
          type: "success",
          title: "Versão Atualizada.",
          description: "Versão atualizada com sucesso.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (err: any) {
        const { ...error } = err;

        if (error?.response?.data?.detail) {
          addToast({
            type: "error",
            title: "Erro ao enviar formulario.",
            description: error?.response?.data?.detail,
          });
        }

        addToast({
          type: "error",
          title: "Erro ao enviar formulario.",
          description:
            "Verifique se nenhum campo esta em branco, caso o erro persista favor entrar em contato com o adminsitrador.",
        });

        console.log({ error });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, selectedTipo, selectedCheck]
  );

  useEffect(() => {
    getVersionId();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ContainerPrincipal>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={dadosVersion}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Status"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                  name="is_active"
                  defaultValue={CheckOptions?.filter(
                    (data?: any) => data?.value === dadosVersion?.is_active
                  )}
                  className="select"
                  isSearchable={false}
                  value={selectedCheck}
                  onChange={handleCheckChange}
                  options={CheckOptions}
                />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Versão"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input id="version" name="version" />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Data da Versão"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FilterCalendarDateTime name="release_date" />
                {/* <Input id="release_date" name="release_date" /> */}
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Nota da Versão"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input
                  id="release_note"
                  name="release_note"
                  multiline
                  className="Multiline"
                />
              </ListItem>
            </Stack>
            <ButtonStyled
              color="purple"
              padding="1"
              type="submit"
              className="searchButton"
              style={{
                marginLeft: "5px",
                marginTop: pageSize ? "0px" : "0px",
                fontWeight: "bold",
              }}
            >
              Atualizar
            </ButtonStyled>
          </Form>
        </ContainerPrincipal>
      )}
    </>
  );
};

export default AppLayoutId;
