/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosPromise } from "axios";
import api from "./api";
import { Filters, RechargeFilter } from "../interfaces/recharge";

interface Banners {
  backgroundCor: string;
  backgroundImage: string;
  banner_type: string;
  created_at: string;
  cta_color: string;
  cta_text: string;
  id: number;
  is_active: boolean;
  media_link: string;
  updated_at: string;
}

const ConvertArrayFilterToObject = (filters: Filters = {}): RechargeFilter => {
  try {
    const object = filters.reduce(
      (obj: any, item: any) => ({ ...obj, ...item }),
      {}
    );
    return object;
  } catch (err) {
    return {};
  }
};

const SerializeFilters = (filters: any): string => {
  const str = [];
  for (const p in filters) {
    if (filters.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(filters[p])}`);
    }
  }
  return str.join("&");
};

class CoreService {
  getBannersMobile = (): AxiosPromise => {
    const url = "core/manager/banner-mobile/";
    return api.get(url);
  };

  getBannerMobId = (id: string): AxiosPromise => {
    const url = `/core/manager/banner-mobile/${id}/`;
    return api.get(url);
  };

  patchBannerMob = (id: string, data: any) => {
    return api.patch(`/core/manager/banner-mobile/${id}/`, data);
  };

  postBannerMob = (data: any) => {
    return api.post("/core/manager/banner-mobile/", data);
  };

  deleteBannerMobId = (id: string): AxiosPromise => {
    const url = `/core/manager/banner-mobile/${id}/`;
    return api.delete(url);
  };

  getBannersWeb = (): AxiosPromise => {
    const url = "core/manager/banner-web/";
    return api.get(url);
  };

  getBannerWebId = (id: string): AxiosPromise => {
    const url = `/core/manager/banner-web/${id}/`;
    return api.get(url);
  };

  patchBannerWeb = (id: string, data: any) => {
    return api.patch(`/core/manager/banner-web/${id}/`, data);
  };

  postBannerWeb = (data: any) => {
    return api.post("/core/manager/banner-web/", data);
  };

  deleteBannerWebId = (id: string): AxiosPromise => {
    const url = `/core/manager/banner-web/${id}/`;
    return api.delete(url);
  };

  getAppVersion = (): AxiosPromise => {
    const url = "core/manager/app-version/";
    return api.get(url);
  };

  patchAppVersion = (id: string, data: any) => {
    return api.patch(`/core/manager/app-version/${id}/`, data);
  };

  deleteAppVersion = (id: string): AxiosPromise => {
    const url = `/core/manager/app-version/${id}/`;
    return api.delete(url);
  };

  getAppVersionId = (id: string): AxiosPromise => {
    const url = `/core/manager/app-version/${id}/`;
    return api.get(url);
  };

  postAppVersion = (data: any) => {
    return api.post("/core/manager/app-version/", data);
  };

  getRecargaLogs = (filters?: Filters, id?: string): AxiosPromise => {
    const convertedFilters = ConvertArrayFilterToObject(filters.filters);
    const url =
      Object.keys(convertedFilters).length === 0
        ? `ticketing/manager/recharge-request-log/?recharge_id=${id}`
        : `ticketing/manager/recharge-request-log/?recharge_id=${id}&${SerializeFilters(
            convertedFilters
          )}`;
    return api.get(url);
  };

  postRecargaUpdate = (id: string): AxiosPromise => {
    const url = `ticketing/manager/recharge-request/${id}/check/`;
    return api.post(url);
  };

  postCancelarRecargaQrCode = (id: string): AxiosPromise => {
    const url = `ticketing/manager/recharge-request/${id}/cancel-recharge/`;
    return api.post(url);
  };

  getSettings = (): AxiosPromise => {
    const url = "settings/";
    return api.get(url);
  };

  patchSettings = (data: any): AxiosPromise => {
    return api.patch("settings/update/", data);
  };
}

export type { Banners };

export default CoreService;
