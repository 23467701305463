/* eslint-disable react/require-default-props */
import React from "react";
import { AuthProvider } from "./auth";
import { LoaderProvider } from "./loader";
import { ToastProvider } from "./toast";

interface ChildrenProps {
  children?: any;
}

const AppProvider: React.FC<ChildrenProps> = ({ children }) => (
  <AuthProvider>
    <LoaderProvider>
      <ToastProvider>{children}</ToastProvider>
    </LoaderProvider>
  </AuthProvider>
);

export default AppProvider;
