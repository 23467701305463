import styled from "styled-components";

interface BorderProps {
  Border: boolean;
}

export const StyledIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  color: var(--purple);
  :hover {
    background-color: var(--purple);
    color: var(--white);
  }
  p {
    margin-right: 5px;
    font-weight: bold;
    font-size: 12px;
  }
`;
export const StyledDiv = styled.div<BorderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--purple);
  ${(props) =>
    props.Border
      ? `border-top: 2px solid var(--orange); border-bottom: 2px solid var(--orange);`
      : `border: 2px solid var(--orange);`}
  border-radius: 16px;
  padding: 2px;
`;
