/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import * as Yup from "yup";

import {
  Avatar,
  Box,
  Container,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LockOutlined } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";

import { ItemText } from "../../itemText";
import theme from "../../../theme";
import PorDataPedido from "./pordatapedido";
import Status from "./status";
import RecomendacaoKonduto from "./recomendKonduto";
import PorTipoPagamento from "./portipopagamento";
import PorEstocagemDuplicada from "./estocagemduplicada";
import PorTipoCartao from "./portipocartao";
import PorOrigem from "./pororigem";
import PorLogs from "./porlogs";
import PorGateway from "./porgateway";

interface FilterProps {
  aside?: any;
  datainicial?: any;
  datafinal?: any;
  tipoStatus?: any;
  tipoRecKonduto?: any;
  tipopagamento?: any;
  tipoestocduplicada?: any;
  tipocartao?: any;
  porOrigem?: any;
  porLogs?: any;
  porGateway?: any;
}

const FiltroRecargas: React.FC<FilterProps> = ({
  aside,
  datainicial,
  datafinal,
  tipoStatus,
  tipoRecKonduto,
  tipopagamento,
  tipoestocduplicada,
  tipocartao,
  porOrigem,
  porGateway,
  porLogs,
}) => {
  const [dadosPorpedidoIni, setDadosPorpedidoIni] = React.useState();
  const [dadosPorpedidoFnl, setDadosPorpedidoFnl] = React.useState();
  const [dadosPorStatus, setDadosPorStatus] = React.useState();
  const [dadosPorRecKonduto, setDadosPorRecKonduto] = React.useState();
  const [dadosPorTipoPagamento, setDadosPorTipoPagamento] = React.useState();
  const [dadosPorTipoEstocagem, setDadosPorTipoEstocagem] = React.useState();
  const [dadosPorTipoCartao, setDadosPorTipoCartao] = React.useState();
  const [dadosPorOrigem, setDadosPorOrigem] = React.useState();
  const [dadosPorGateway, setDadosPorGateway] = React.useState();
  const [dadosPorLogs, setDadosPorLogs] = React.useState();

  // Defina a função de callback para receber os dados do componente filho
  const receberDadosPorpedidoIni = (dados: any) => {
    setDadosPorpedidoIni(dados);
    datainicial(dados);
  };
  const receberDadosPorpedidoFnl = (dados: any) => {
    datafinal(dados);
    setDadosPorpedidoFnl(dados);
  };

  const receberDadosStatus = (dados: any) => {
    tipoStatus(dados);
    setDadosPorStatus(dados);
  };
  const receberDadosRecKonduto = (dados: any) => {
    tipoRecKonduto(dados);
    setDadosPorRecKonduto(dados);
  };
  const receberDadosTipoPagamento = (dados: any) => {
    tipopagamento(dados);
    setDadosPorTipoPagamento(dados);
  };

  const receberDadosTipoEstocagem = (dados: any) => {
    tipoestocduplicada(dados);
    setDadosPorTipoEstocagem(dados);
  };

  const receberDadosTipoCartao = (dados: any) => {
    tipocartao(dados);
    setDadosPorTipoCartao(dados);
  };

  const receberDadosPorOrigem = (dados: any) => {
    porOrigem(dados);
    setDadosPorOrigem(dados);
  };

  const receberDadosPorLogs = (dados: any) => {
    porLogs(dados);
    setDadosPorLogs(dados);
  };

  const receberDadosPorGateway = (dados: any) => {
    porGateway(dados);
    setDadosPorGateway(dados);
  };

  React.useEffect(() => {}, [
    dadosPorpedidoIni,
    dadosPorpedidoFnl,
    dadosPorStatus,
    dadosPorRecKonduto,
    dadosPorTipoPagamento,
    dadosPorTipoCartao,
    dadosPorOrigem,
    dadosPorGateway,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--aside-background)",
        display: "flex",
        marginLeft: 2,
        marginRight: 2,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: aside ? "inherit" : "200px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            minWidth: aside ? "100%" : "300px",
          }}
        >
          <Stack>
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <ItemText
                  primary="Filtros"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontSize: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
          <Stack
            sx={{
              padding: "5px 10px 5px 10px",
            }}
          >
            <PorLogs porLogs={receberDadosPorLogs} />
            <PorDataPedido
              datainicial={receberDadosPorpedidoIni}
              datafinal={receberDadosPorpedidoFnl}
            />
            <Status tipoStatus={receberDadosStatus} />
            <RecomendacaoKonduto tipoRecKonduto={receberDadosRecKonduto} />
            <PorTipoPagamento tipopagamento={receberDadosTipoPagamento} />
            {/* <PorEstocagemDuplicada
              tipoestocduplicada={receberDadosTipoEstocagem}
            /> */}
            <PorTipoCartao tipocartao={receberDadosTipoCartao} />
            <PorOrigem porOrigem={receberDadosPorOrigem} />
            <PorGateway porGateway={receberDadosPorGateway} />
          </Stack>
        </Box>
      </List>
    </Box>
  );
};

export { FiltroRecargas };
