/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  InputHTMLAttributes,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";
import * as Yup from "yup";
import { Container, Error, Label } from "./styles";

interface InputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  name: string;
  schema?: Yup.ObjectSchema;
  rounded?: boolean;
  birth_date?: boolean;
  cpf?: boolean;
  cnpj?: boolean;
  cardnumber?: boolean;
  cardtransfacil?: boolean;
  cpforcnpj?: boolean;
  phone?: boolean;
  cep?: boolean;
  multiline?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
}

const InputStyled: React.FC<InputProps> = ({
  name,
  label,
  rounded,
  schema,
  birth_date,
  cpf,
  cnpj,
  cpforcnpj,
  phone,
  cep,
  cardnumber,
  cardtransfacil,
  icon: Icon,
  children,
  multiline,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleChange = useCallback(async () => {
    try {
      const isValid = await Yup.reach(schema, fieldName).validate(
        inputRef.current?.value,
        { abortEarly: true }
      );
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  const handleKeyUpBirthDate = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 10;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{2})(\d)/, "$1/$2");
      value = value.replace(/(\d{2})(\d)/, "$1/$2");
      value = value.replace(/(\d{2})(\d{2})$/, "$1$2");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCpf = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 14;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 18;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/, "$1.$2");
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCpfOrCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 18;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      if (value.length <= 14) {
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      } else {
        value = value.replace(/^(\d{2})(\d)/, "$1.$2");
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
      }
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpPhone = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 15;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCardNumber = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 19;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{4})(\d)/g, "$1 $2");
      value = value.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3");
      value = value.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCardTransfacil = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 16;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{14})(\d)/, "$1-$2");
      e.currentTarget.value = value;
    },
    []
  );

  const handleKeyUpCep = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.currentTarget.maxLength = 9;
      let { value } = e.currentTarget;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d)/, "$1-$2");
      e.currentTarget.value = value;
    },
    []
  );

  const getHandleKeyUp = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let handleFunction;
    if (cpf) handleFunction = handleKeyUpCpf(e);
    if (cnpj) handleFunction = handleKeyUpCnpj(e);
    if (cpforcnpj) handleFunction = handleKeyUpCpfOrCnpj(e);
    if (birth_date) handleFunction = handleKeyUpBirthDate(e);
    if (phone) handleFunction = handleKeyUpPhone(e);
    if (cardnumber) handleFunction = handleKeyUpCardNumber(e);
    if (cardtransfacil) handleFunction = handleKeyUpCardTransfacil(e);
    if (cep) handleFunction = handleKeyUpCep(e);
    return handleFunction;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isCorrect={isCorrect}
        rounded={rounded}
        multiline={multiline}
      >
        {multiline && (
          <textarea
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onKeyUp={(e) => getHandleKeyUp(e)}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />
        )}

        {!multiline && (
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            onKeyUp={(e) => getHandleKeyUp(e)}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
            disabled={disabled}
          />
        )}

        {children}
        {Icon && <Icon size={20} />}
      </Container>
      {!!error && <Error>{error}</Error>}
    </>
  );
};

export default InputStyled;
