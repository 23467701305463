/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  Stack,
  useMediaQuery,
  Button,
  ListItemButton,
  Tooltip,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormHandles } from "@unform/core";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Form } from "@unform/web";
import { ItemText } from "../../../components/itemText";
import theme from "../../../theme";
import HeaderComp from "../../../components/header";
import { Container, TabelaDiv } from "./styles";
import { ArrayFilters } from "../../../interfaces/filterSearch";
import { useToast } from "../../../hooks/toast";
import { BannerTable } from "./Components/BannerTable";
import { useAuth } from "../../../hooks/auth";
import AccountsService from "../../../services/accounts";
import Loader from "../../../components/Loader";
import CoreService from "../../../services/core";
import Input from "../../../components/Input";

interface DadosFiltros {
  value: string;
  label: string;
}

const Settings: React.FC = () => {
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const servicesacc = { AccountsService: new AccountsService() };
  const services = { coreServices: new CoreService() };

  const pageSize = useMediaQuery("(max-width:1600px)");

  const [alertaOn, setAlertaOn] = React.useState<boolean>(false);
  const [alterarOn, setAlterarOn] = React.useState<boolean>(false);
  const [selectedQrCode, setSelectedQrCode] = React.useState<boolean>();
  const [selectedScape, setSelectedScape] = React.useState<boolean>();
  const [pedidoAlterado, setPedidoAlterado] = React.useState<boolean>(false);

  const [dadosBusca, setDadosBusca] = React.useState("");

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{}],
  });
  const [allBanners, setAllBanners] = useState<any>();

  const recargaOptions = [
    { value: "external_code", label: "Codigo Externo" },
    { value: "email", label: "Email" },
    { value: "cpf", label: "CPF" },
    { value: "mundipagg_order_id", label: "ID Mundipagg" },
    { value: "tacom_order_id", label: "ID Tacom" },
    { value: "code_stone", label: "Codigo Stone" },
    { value: "card", label: "Cartão Transfácil" },
  ];

  const [selectedRecarga, setSelectedRecarga] = useState(recargaOptions[0]);

  const handleRecargaChange = useCallback((selectedOption) => {
    setSelectedRecarga(selectedOption);
  }, []);

  const formRef = React.useRef<FormHandles>(null);

  const getRechargeList = useCallback(async () => {
    setLoading(true);
    const response = await services.coreServices.getSettings();
    console.log(response?.data?.results?.value_type);
    const maping = response?.data?.results.filter((data: any) =>
      data?.value_type === "bool" && data?.name === "CREATE_QRCODE_TICKET_USE"
        ? setSelectedQrCode(data?.value)
        : data?.value_type === "bool" && data?.name === "SCAPE_SQS"
        ? setSelectedScape(data?.value)
        : ""
    );
    setAllBanners(response?.data);
    setTotalRows(response.data.count);
    const pages = response.data.count / 40;
    setLastPage(Math.ceil(pages));
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [filters, dadosBusca]);

  const filteredFilters = filters.filters.filter(
    (item) => !item.hasOwnProperty("page")
  );

  const handleChangePerPage = useCallback(
    async (newPage: number) => {
      setPage(newPage);
      setFilters({ filters: [...filters.filters, { page: newPage }] });
    },
    [filters]
  );

  const handleCheckBoxChange = useCallback(() => {
    setSelectedQrCode(!selectedQrCode);
  }, [selectedQrCode, selectedScape]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        // Inicialize o FormData
        const formData = new FormData();
        const updatedBanners = allBanners.results.map((banner: any) => {
          if (banner.name === "NUMBER_BHBUS_ORDER_CAN_USE_CREDIT_CARD_BY_DAY") {
            return {
              ...banner,
              value: parseInt(
                data.NUMBER_BHBUS_ORDER_CAN_USE_CREDIT_CARD_BY_DAY,
                10
              ),
            };
          } else if (banner.name === "DEPENDENT_CARD_STOCK_QUEUE_SIZE") {
            return {
              ...banner,
              value: parseInt(data.DEPENDENT_CARD_STOCK_QUEUE_SIZE, 10),
            };
          } else if (banner.name === "HOLDER_CARD_STOCK_QUEUE_SIZE") {
            return {
              ...banner,
              value: parseInt(data.HOLDER_CARD_STOCK_QUEUE_SIZE, 10),
            };
          } else if (banner.name === "NUMBER_USER_CAN_USE_CREDIT_CARD_BY_DAY") {
            return {
              ...banner,
              value: parseInt(data.NUMBER_USER_CAN_USE_CREDIT_CARD_BY_DAY, 10),
            };
          } else if (banner.name === "VIRTUAL_CARD_STOCK_QUEUE_SIZE") {
            return {
              ...banner,
              value: parseInt(data.VIRTUAL_CARD_STOCK_QUEUE_SIZE, 10),
            };
          } else if (banner.name === "CREATE_QRCODE_TICKET_USE") {
            return {
              ...banner,
              value: selectedQrCode,
            };
          } else if (banner.name === "SCAPE_SQS") {
            return {
              ...banner,
              value: selectedScape,
            };
          }
          return banner;
        });

        console.log(updatedBanners);

        // Adicionar os valores atualizados ao FormData
        updatedBanners.forEach((banner: any) => {
          formData.append(`banners[${banner.id}][name]`, banner.name);
          formData.append(`banners[${banner.id}][value]`, banner.value);
          formData.append(
            `banners[${banner.id}][value_type]`,
            banner.value_type
          );
        });

        console.log(updatedBanners);

        // Configuração do cabeçalho para a requisição
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const response = await services.coreServices.patchSettings(
          updatedBanners
        );

        console.log(response);

        addToast({
          type: "success",
          title: "Settings atualizado.",
          description: "Dados dos Settings foram atualizados com sucesso.",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (err: any) {
        const { ...error } = err;

        // Mostrar mensagem de erro
        addToast({
          type: "error",
          title: "Erro ao enviar formulário.",
          description:
            "Verifique se nenhum campo está em branco, caso o erro persista, favor entrar em contato com o administrador.",
        });

        console.log({ error });
      }
    },
    [addToast, selectedQrCode, selectedScape]
  );

  useEffect(() => {
    getRechargeList();
  }, [page, filters]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("RecargaStatus");

      // Exemplo: salvar dados no localStorage antes de sair
      localStorage.setItem("lastVisit", new Date().toISOString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remover o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {}, [dadosBusca, filters, filteredFilters, dadosBusca]);

  useEffect(() => {
    if (pedidoAlterado) {
      getRechargeList();
      setPedidoAlterado(false);
    }
  }, [pedidoAlterado]);

  useEffect(() => {}, [selectedQrCode, selectedScape]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderComp filtroNaparece={true} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
            }}
          >
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Container>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "RGB(248, 248, 248)",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        overflowX: "auto",
                        display: "grid",
                      }}
                    >
                      <Stack
                        sx={{
                          zIndex: 0,
                        }}
                      >
                        <ListSubheader
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "10px",
                            backgroundColor: "RGB(248, 248, 248)",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="Configurações"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontSize: pageSize ? "30px" : "20px",
                                fontWeight: "bold",
                              }}
                            />
                          </Box>
                        </ListSubheader>
                      </Stack>
                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={allBanners.results}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {!loading && (
                          <TabelaDiv className="Tabela">
                            <BannerTable
                              data={allBanners.results}
                              columns={[
                                {
                                  name: <div>ID</div>,
                                  selector: "id",
                                  cell: ({ id }: any) => (
                                    <div className="specifitcell">{id}</div>
                                  ),
                                },
                                {
                                  name: <div>Nome</div>,
                                  selector: "name",
                                  cell: ({ name }: any) => (
                                    <div className="specifitcell">{name}</div>
                                  ),
                                },
                                {
                                  name: <div>Descrição</div>,
                                  selector: "description",
                                  cell: ({ description }: any) => (
                                    <div className="specifitcell">
                                      {description}
                                    </div>
                                  ),
                                },
                                {
                                  name: <div>Valor</div>,
                                  selector: "action",
                                  cell: ({
                                    id,
                                    value,
                                    value_type,
                                    name,
                                  }: any) => (
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                      }}
                                    >
                                      {value_type === "bool" ? (
                                        name === "CREATE_QRCODE_TICKET_USE" ? (
                                          selectedQrCode === true ? (
                                            <Tooltip
                                              title="Deseja Desativar?"
                                              onClick={handleCheckBoxChange}
                                            >
                                              <CheckBoxIcon className="iconCheck" />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Deseja Ativar?"
                                              onClick={handleCheckBoxChange}
                                            >
                                              <CheckBoxOutlineBlankIcon className="iconCheck" />
                                            </Tooltip>
                                          )
                                        ) : selectedScape === true ? (
                                          <Tooltip
                                            title="Deseja Desativar?"
                                            onClick={handleCheckBoxChange}
                                          >
                                            <CheckBoxIcon className="iconCheck" />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Deseja Ativar?"
                                            onClick={handleCheckBoxChange}
                                          >
                                            <CheckBoxOutlineBlankIcon className="iconCheck" />
                                          </Tooltip>
                                        )
                                      ) : (
                                        <div>
                                          <Input
                                            id={`${id}`}
                                            name={`${name}`}
                                            defaultValue={value}
                                          />
                                        </div>
                                      )}
                                    </Stack>
                                  ),
                                },
                              ]}
                            />
                          </TabelaDiv>
                        )}
                        <Button
                          type="submit"
                          sx={{
                            backgroundColor: "var(--purple)",
                            color: "white",
                            ":hover": {
                              color: "var(--orange)",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          Salvar
                        </Button>
                      </Form>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 2,
                          marginBottom: 2,
                          fontWeight: "bold",
                        }}
                      >
                        <Button
                          disabled={allBanners?.previous === null}
                          onClick={() => handleChangePerPage(1)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Primeira Página
                        </Button>
                        <Button
                          disabled={allBanners?.previous === null}
                          onClick={() => handleChangePerPage(page - 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Anterior
                        </Button>
                        <Box
                          sx={{ margin: 2 }}
                        >{`Página ${page} de ${lastPage}`}</Box>
                        <Button
                          disabled={allBanners?.next === null}
                          onClick={() => handleChangePerPage(page + 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Próxima
                        </Button>
                        <Button
                          disabled={allBanners?.next === null}
                          onClick={() => handleChangePerPage(lastPage)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Última Página
                        </Button>
                      </Box>
                    </Box>
                  </List>
                </Container>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Settings;
