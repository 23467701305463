import styled from "styled-components";

export const Container = styled.div`
  h1 {
    color: var(--purple);
  }
  .custom-link:hover {
    font-weight: bold;
    font-size: 16px;
  }
  .custom-link {
    color: #3f1159;
    font-weight: bold;
  }

  .BotaoLimpar:hover {
    color: #3f1159;
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }
  .rdt_TableCol {
    min-width: 150px;
    text-align: center;
    justify-content: center;
    @media only screen and (max-width: 500px) {
      min-width: 50px;
    }
  }
  .rdt_TableHead {
    display: flex;
    width: auto;
  }
  .rdt_TableCol:nth-child(3) {
    max-width: 90px;
    text-align: center;
    justify-content: center;
    @media only screen and (max-width: 500px) {
      max-width: 50px;
    }
  }

  .Tabela {
    width: 98%;
    margin: 1%;
    overflow-x: auto;
    justify-content: center;
    .specifitcell {
      width: 100%;
    }
    .specifitcellS {
      width: 100%;
    }

    #cell-1-undefined {
      padding: 0px 5px;
      max-width: 200px;
    }
    #cell-2-undefined {
      width: 100px;
      padding: 0px 5px;
      @media only screen and (max-width: 500px) {
        width: 100px;
      }
    }
    div[data-column-id="1"] {
      max-width: fit-content;
      min-width: 80px;
      justify-content: center;
      @media only screen and (max-width: 900px) {
        max-width: fit-content;
      }
    }

    #cell-3-undefined {
      padding: 0px 5px;
      max-width: 150px;
      @media only screen and (max-width: 400px) {
        max-width: 80px;
      }
    }
    /* #cell-4-undefined {
      padding: 0px 5px;
    } */
  }

  .tableMaxWidth {
    width: 100%;
    overflow-x: auto;

    @media (max-width: 1200px) {
      overflow-x: auto;
    }
    .id_cell {
      width: 2px;
    }
  }

  .MuiTableCell-body {
    .id_cell {
      min-width: 2px;
    }
  }
  .MuiTableCell-root {
    max-width: 100%;
    padding: 10px;
    text-align: center;
    @media (max-width: 600px) {
      display: flex;
      font-size: 0.8rem;
      padding: 5px;
    }

    div {
      color: var(--purple) !important;
      font-weight: bold;
    }
  }
  .MuiTableCell-head {
    width: 100%;
  }

  .header {
    background-color: var(--aside-background);
    strong {
      color: var(--purple);
      .id_cell {
        width: 50px;
      }
    }
  }
`;
