import styled from "styled-components";

export const Container = styled.div`
  .modal-cpf-label {
    margin-top: 20px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    @media (max-width: 650px) {
      display: grid;
    }
  }

  .logout {
    background-color: var(--purple);
    justify-content: center;
    border-radius: 18px;
    width: 200px;
    height: 40px;
    color: white;
    margin-left: 50px;
    .iconlog {
      margin-right: 10px;
      padding-top: 1px;
    }
    @media (max-width: 800px) {
      width: 100%;
      margin-left: 0px;
      margin-top: 20px;
    }
    font-size: 20px;
  }

  .editar {
    font-size: 0.8em;
    font-weight: bold;
    padding-top: 25px;

    cursor: pointer;
    @media (max-width: 680px) {
      padding-top: 0px;
    }
  }

  .head {
    margin-top: 20px;
  }

  .item {
    width: 40%;
    @media (max-width: 680px) {
      width: 100%;
      margin-top: 10px;
    }
    .info {
      font-size: 12px;
      color: var(--orange);
      font-weight: bold;
      margin-top: 0;
    }
  }

  .ml {
    margin-left: 40px;
    h4 {
      color: var(--title-highlight);
      font-weight: normal;
      margin-top: 5px;
      margin-bottom: 15px;
    }
    b {
      margin-left: 15px;
      color: var(--purple);
    }
    @media (max-width: 680px) {
      margin-left: 0px;
    }
  }
  .ml2 {
    margin-left: 40px;
    background-color: #ebf1f4;
    border-radius: 10px;
    padding: 2;
    h4 {
      color: var(--title-highlight);
      font-weight: normal;
      margin-top: 5px;
      margin-left: 5px;
      margin-bottom: 15px;
    }
    b {
      margin-left: 15px;
      color: var(--purple);
    }
    @media (max-width: 680px) {
      margin-left: 0px;
    }
  }

  .flex {
    display: flex;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 680px) {
      flex-direction: column;
      margin-top: 0px;
    }

    p {
      margin-top: 20px;
    }

    span {
      display: inline-block;
      margin-top: 45px;
      margin-left: -75px;
      color: var(--orange);

      @media (max-width: 680px) {
        margin-top: 8px;
        text-align: right;
      }
    }
  }

  .termos {
    display: inline-block;
    margin-top: 30px;

    @media (max-width: 680px) {
      width: 100%;
    }
  }

  .text-purple {
    color: var(--purple);
    font-weight: bold;
  }

  .endereco {
    margin-top: 10px;
    width: 30vw;
    height: 100px;

    Input {
      height: 100%;
    }
  }

  .btn-cancelar {
    margin-left: 50px;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;
