import styled from "styled-components";

export const Container = styled.div`
  h1 {
    color: var(--purple);
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }
`;

export const TitleSection = styled.section`
  margin: 60px 0 60px 0;
  display: flex;
  flex-direction: row;
  color: var(--purple);

  .icon {
    margin-right: 20px;
  }

  h1 {
    font-size: 3rem;
    line-height: 2.3rem;
    @media (max-width: 492px) {
      line-height: 3.5rem;
    }
  }
`;

export const FormWrapper = styled.div`
  .modal-cpf-label {
    margin-top: 20px;
  }

  .btn-wrapper {
    margin-top: 20px;
  }

  .editar {
    cursor: pointer;
    padding-top: 25px;
  }

  .head {
    margin-top: 20px;
  }

  .item {
    width: 40%;
  }

  .ml {
    margin-left: 40px;
  }

  .flex {
    display: flex;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 680px) {
      flex-direction: column;
    }

    p {
      margin-top: 20px;
    }

    span {
      display: inline-block;
      margin-top: 45px;
      margin-left: -30px;
      color: var(--orange);

      @media (max-width: 680px) {
        margin-top: 8px;
        text-align: right;
      }
    }
  }

  .termos {
    display: inline-block;
    margin-top: 30px;

    @media (max-width: 680px) {
      width: 100%;
    }
  }

  .text-purple {
    color: var(--purple);
    font-weight: bold;
  }

  .endereco {
    margin-top: 10px;
    width: 30vw;
    height: 100px;

    Input {
      height: 100%;
    }
  }

  .btn-cancelar {
    margin-left: 50px;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const ModalSenha = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .wrapper {
    background-color: #fff;
    width: 600px;
    height: 380px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: 1;
    border: 1px solid #cbcbcb;

    .close {
      background-color: transparent;
      outline: none;
      width: 32px;
      height: 32px;
      right: calc(-100%);
      top: 10px;
      cursor: pointer;
      display: flex;
      position: relative;

      &:before,
      &:after {
        content: " ";
        position: absolute;
        width: 2.5px;
        height: 24px;
        background-color: #000;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }
  .btn-sair {
    margin-left: 30px;
  }
  .btn-wrapper {
    margin-top: 20px;
  }
  .mt-20 {
    padding-top: 20px;
  }

  .btn-right {
    margin-left: 20px;
  }
`;
