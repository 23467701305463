/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { Container } from "./styles";
import TicketingService from "../../../../services/ticketing";
import Loader from "../../../../components/Loader";
import { useToast } from "../../../../hooks/toast";
import CoreService from "../../../../services/core";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "#472b74",
  borderRadius: "12px",
};

interface AlertaInterface {
  open?: boolean;
  handleClose?: () => void;
  show?: boolean;
  id?: any;
}

const ModalExcluir: React.FC<AlertaInterface> = ({
  show,
  handleClose,
  open,
  id,
}) => {
  const services = { coreServices: new CoreService() };
  const { addToast } = useToast();
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleExcluir = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.coreServices.deleteBannerWebId(id);
    } catch (err: any) {
      const { ...error } = err;

      console.log(error);

      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
      handleClose();
      window.location.reload();
      addToast({
        type: "success",
        title: `Sucesso`,
        description: `Status do Pedido alterado com Sucesso.`,
      });
    }
  }, []);

  return (
    <Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "10px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="title"
            sx={{
              fontWeight: "bold",
            }}
          >
            Banner de Id: {id} será excluído.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2 }}
            className="text"
          >
            Clique em Excluir para excluir o banner ou clique fora do modal para
            cancelar.
          </Typography>
          <Button
            onClick={handleExcluir}
            sx={{
              backgroundColor: "var(--purple)",
              color: "white",
              ":hover": {
                color: "var(--orange)",
                fontWeight: "bold",
              },
            }}
          >
            Excluir
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ModalExcluir;
