/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  Stack,
  useMediaQuery,
  Button,
  ListItemButton,
  Tooltip,
} from "@mui/material";

import { formatCurrency } from "@brazilian-utils/brazilian-utils";

import { Form } from "@unform/web";
import { MdSearch } from "react-icons/md";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FormHandles } from "@unform/core";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ItemText } from "../../../components/itemText";
import theme from "../../../theme";
import HeaderComp from "../../../components/header";
import { Container, TabelaDiv } from "./styles";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ButtonStyled from "../../../components/Button";
import { ArrayFilters } from "../../../interfaces/filterSearch";
import { useToast } from "../../../hooks/toast";
import TicketingService from "../../../services/ticketing";
import { RechargesResponse } from "../../../interfaces/recharge";
import { BannerTable } from "./Components/BannerTable";
import { ProfileData } from "../../MeuPerfil";
import { useAuth } from "../../../hooks/auth";
import AccountsService from "../../../services/accounts";
import Loader from "../../../components/Loader";
import ModalVerificar from "./ModalVerificar";
import CoreService from "../../../services/core";
import ModalExcluir from "./ModalExcluir";

interface DadosFiltros {
  value: string;
  label: string;
}

const BannersMobile: React.FC = () => {
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const servicesacc = { AccountsService: new AccountsService() };
  const services = { coreServices: new CoreService() };

  const pageSize = useMediaQuery("(max-width:1600px)");

  const [alertaOn, setAlertaOn] = React.useState<boolean>(false);
  const [excluirOn, setExcluirOn] = React.useState<boolean>(false);
  const [pedidoId, setPedidoId] = React.useState("");
  const [externalPedido, setExternalPedido] = React.useState("");
  const [pedidoVerificado, setPedidoVerificado] =
    React.useState<boolean>(false);
  const [pedidoAlterado, setPedidoAlterado] = React.useState<boolean>(false);

  const [dadosBusca, setDadosBusca] = React.useState("");

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{}],
  });
  const [allBanners, setAllBanners] = useState<any>();

  const recargaOptions = [
    { value: "external_code", label: "Codigo Externo" },
    { value: "email", label: "Email" },
    { value: "cpf", label: "CPF" },
    { value: "mundipagg_order_id", label: "ID Mundipagg" },
    { value: "tacom_order_id", label: "ID Tacom" },
    { value: "code_stone", label: "Codigo Stone" },
    { value: "card", label: "Cartão Transfácil" },
  ];

  const [selectedRecarga, setSelectedRecarga] = useState(recargaOptions[0]);

  const handleRecargaChange = useCallback((selectedOption) => {
    setSelectedRecarga(selectedOption);
  }, []);

  const formRef = React.useRef<FormHandles>(null);

  const recebeId = (idalerta: any, isverificado: boolean, external: any) => {
    setPedidoVerificado(isverificado);
    setPedidoId(idalerta);
    setExternalPedido(external);
    setAlertaOn(true);
  };

  const DeletaId = (idalerta: any) => {
    setPedidoId(idalerta);
    setExcluirOn(true);
  };

  const getRechargeList = useCallback(async () => {
    setLoading(true);
    const response = await services.coreServices.getBannersMobile();
    setAllBanners(response?.data);
    setTotalRows(response.data.count);
    const pages = response.data.count / 40;
    setLastPage(Math.ceil(pages));
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [filters, dadosBusca]);

  const handleClearFilters = useCallback(() => {
    formRef.current?.reset();
    setFilters({
      filters: [{ page }],
    });
    window.location.reload();
  }, []);

  const filteredFilters = filters.filters.filter(
    (item) => !item.hasOwnProperty("page")
  );

  const handleChangePerPage = useCallback(
    async (newPage: number) => {
      setPage(newPage);
      setFilters({ filters: [...filters.filters, { page: newPage }] });
    },
    [filters]
  );

  useEffect(() => {
    getRechargeList();
  }, [page, filters]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("RecargaStatus");

      // Exemplo: salvar dados no localStorage antes de sair
      localStorage.setItem("lastVisit", new Date().toISOString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remover o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {}, [dadosBusca, filters, filteredFilters, dadosBusca]);

  useEffect(() => {
    if (pedidoAlterado) {
      getRechargeList();
      setPedidoAlterado(false);
    }
  }, [pedidoAlterado]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderComp filtroNaparece={true} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
            }}
          >
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Container>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "RGB(248, 248, 248)",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        overflowX: "auto",
                        display: "grid",
                      }}
                    >
                      <Stack
                        sx={{
                          zIndex: 0,
                        }}
                      >
                        <ListSubheader
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "10px",
                            backgroundColor: "RGB(248, 248, 248)",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="Banners Mobile"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontSize: pageSize ? "30px" : "20px",
                                fontWeight: "bold",
                              }}
                            />
                          </Box>
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "right",
                              alignContent: "center",
                            }}
                          >
                            <Link to="/bannermobnew">
                              <h3 className="addBanner">
                                Novo Banner
                                <AddIcon className="iconNew" />
                              </h3>
                            </Link>
                          </Stack>
                        </ListSubheader>
                      </Stack>
                      {!loading && (
                        <TabelaDiv className="Tabela">
                          <BannerTable
                            data={allBanners.results}
                            columns={[
                              {
                                name: <div>Ação</div>,
                                selector: "action",
                                cell: ({
                                  id,
                                  needs_to_be_verified,
                                  external_code,
                                }: any) => (
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <Link
                                      to={`/bannermob/${id}`}
                                      className="custom-link"
                                    >
                                      <Tooltip title="Visualizar Banner">
                                        <VisibilityIcon className="iconView" />
                                      </Tooltip>
                                    </Link>
                                    {/* {needs_to_be_verified === true ? (
                                      <Tooltip
                                        title="Pedido Verificado!"
                                        onClick={() =>
                                          recebeId(
                                            id,
                                            needs_to_be_verified,
                                            external_code
                                          )
                                        }
                                      >
                                        <CheckBoxIcon className="iconCheck" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Verificar Pedido?"
                                        onClick={() =>
                                          recebeId(
                                            id,
                                            needs_to_be_verified,
                                            external_code
                                          )
                                        }
                                      >
                                        <CheckBoxOutlineBlankIcon className="iconCheck" />
                                      </Tooltip>
                                    )} */}
                                    <Tooltip
                                      title="Deletar Banner?"
                                      onClick={() => DeletaId(id)}
                                    >
                                      <DeleteIcon className="iconDelete" />
                                    </Tooltip>
                                  </Stack>
                                ),
                              },
                              {
                                name: <div>ID</div>,
                                selector: "id",
                                cell: ({ id }: any) => (
                                  <div className="specifitcell">{id}</div>
                                ),
                              },
                              {
                                name: <div>Status</div>,
                                selector: "is_active",
                                cell: ({ is_active }: any) => (
                                  <div className="specifitcell">
                                    {is_active === true ? (
                                      <strong
                                        style={{ color: "var(--purple)" }}
                                      >
                                        Ativo
                                      </strong>
                                    ) : (
                                      <strong
                                        style={{ color: "var(--orange)" }}
                                      >
                                        Inativo
                                      </strong>
                                    )}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Texto do Botão</div>,
                                selector: "cta_text",
                                cell: ({ cta_text }: any) => (
                                  <div className="specifitcell">{cta_text}</div>
                                ),
                              },
                              {
                                name: <div>Link</div>,
                                selector: "media_link",
                                cell: ({ media_link }: any) => (
                                  <div className="specifitcell">
                                    {media_link}
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </TabelaDiv>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 2,
                          marginBottom: 2,
                          fontWeight: "bold",
                        }}
                      >
                        <Button
                          disabled={allBanners?.previous === null}
                          onClick={() => handleChangePerPage(1)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Primeira Página
                        </Button>
                        <Button
                          disabled={allBanners?.previous === null}
                          onClick={() => handleChangePerPage(page - 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Anterior
                        </Button>
                        <Box
                          sx={{ margin: 2 }}
                        >{`Página ${page} de ${lastPage}`}</Box>
                        <Button
                          disabled={allBanners?.next === null}
                          onClick={() => handleChangePerPage(page + 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Próxima
                        </Button>
                        <Button
                          disabled={allBanners?.next === null}
                          onClick={() => handleChangePerPage(lastPage)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Última Página
                        </Button>
                      </Box>
                    </Box>
                  </List>
                </Container>
              </Box>
            </Box>
          </Box>
          {alertaOn && (
            <ModalVerificar
              open={alertaOn}
              handleClose={() => setAlertaOn(false)}
              id={pedidoId}
              verificado={pedidoVerificado}
              external={externalPedido}
              pedidoAlterado={setPedidoAlterado}
            />
          )}
          {excluirOn && (
            <ModalExcluir
              open={excluirOn}
              handleClose={() => setExcluirOn(false)}
              id={pedidoId}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default BannersMobile;
