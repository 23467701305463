/* eslint-disable no-lonely-if */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
/* eslint-disable import/order */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import * as Yup from "yup";

import { Box, Container, List, ListSubheader, Stack } from "@mui/material";

import { ItemText } from "../itemText";
import theme from "../../theme";
import { Ticketing } from "../homeComponents/ticketing/ticketingView";
import { Accounts } from "../homeComponents/accounts/accountsView";
import { useLoader } from "../../hooks/loader";
import { useAuth } from "../../hooks/auth";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import ContrastIcon from "@mui/icons-material/Contrast";

import {
  GridContainer,
  Header,
  Aside,
  Main,
  MainOverview,
  Footer,
  AsideMenu,
} from "./styles";
import DashBoardLoader from "../DashBoardLoader";
import SideBar from "../sidebar";

interface TiposdeFiltro {
  filtroNaparece?: boolean;
  homeNaparece?: boolean;
  datainicial?: any;
  datafinal?: any;
  tipoStatus?: any;
  tipoRecKonduto?: any;
  tipopagamento?: any;
  tipoestocduplicada?: any;
  tipocartao?: any;
  porOrigem?: any;
  porLogs?: any;
  porGateway?: any;
  dadosFiltros?: any;
}

const HeaderComp: React.FC<TiposdeFiltro> = ({
  filtroNaparece,
  homeNaparece,
  datainicial,
  datafinal,
  tipoStatus,
  tipoRecKonduto,
  tipopagamento,
  tipoestocduplicada,
  tipocartao,
  porOrigem,
  porGateway,
  dadosFiltros,
  porLogs,
}) => {
  const { user, signOut } = useAuth();
  const { push } = useHistory();
  const { isActive } = useLoader();
  const [isDark, setIsDark] = React.useState<boolean>(false);

  const receberDadosPorpedidoIni = (dados: any) => {
    datainicial(dados);
  };
  const receberDadosPorpedidoFnl = (dados: any) => {
    datafinal(dados);
  };
  const receberDadosStatus = (dados: any) => {
    tipoStatus(dados);
  };
  const receberDadosRecKonduto = (dados: any) => {
    tipoRecKonduto(dados);
  };

  const receberDadosTipoPagamento = (dados: any) => {
    tipopagamento(dados);
  };

  const receberDadosTipoEstocagem = (dados: any) => {
    tipoestocduplicada(dados);
  };

  const receberDadosTipoCartao = (dados: any) => {
    tipocartao(dados);
  };

  const receberDadosPorOrigem = (dados: any) => {
    porOrigem(dados);
  };

  const receberDadosPorLogs = (dados: any) => {
    porLogs(dados);
  };

  const receberDadosPorGateway = (dados: any) => {
    porGateway(dados);
  };

  const receberDadosFiltros = (dados: any) => {
    dadosFiltros(dados);
  };

  const localizacao = useLocation();

  const userName = () => {
    return user.full_name ? user.full_name.split(" ")[0] : "";
  };

  const handleRedirect = React.useCallback(
    (pathLink: any) => {
      push(pathLink);
    },
    [push]
  );

  React.useEffect(() => {
    const isDarkYet = localStorage.getItem("isDark");
    if (isDarkYet === "Purple") {
      setIsDark(true);
    } else if (isDarkYet === "Orange") {
      setIsDark(false);
    }
  }, []);

  React.useEffect(() => {
    if (isDark) {
      localStorage.setItem("isDark", "Purple");
    } else {
      localStorage.setItem("isDark", "Orange");
    }
  }, [isDark]); // Esse effect roda toda vez que isDark mudar

  // Função para alternar o estado
  const toggleDarkMode = () => {
    setIsDark((prevIsDark) => !prevIsDark);
  };

  return (
    <Header
      style={{
        background: isDark
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
      }}
    >
      <SideBar
        homeNaparece={homeNaparece}
        filtroNaparece={filtroNaparece}
        tipofiltro={localizacao.pathname}
        datainicial={receberDadosPorpedidoIni}
        datafinal={receberDadosPorpedidoFnl}
        tipoStatus={receberDadosStatus}
        tipoRecKonduto={receberDadosRecKonduto}
        tipopagamento={receberDadosTipoPagamento}
        tipoestocduplicada={receberDadosTipoEstocagem}
        tipocartao={receberDadosTipoCartao}
        porOrigem={receberDadosPorOrigem}
        porLogs={receberDadosPorLogs}
        porGateway={receberDadosPorGateway}
        isDark={isDark}
        dadosFiltros={receberDadosFiltros}
      />
      <div className="header-wrap">
        <div className="profile">
          <div className="profile-data">
            <h3>
              {user.full_name ? (
                <a href="/meu-perfil">
                  Olá, {userName()}
                  {/* <button type="button" onClick={signOut}>
                    Sair
                  </button> */}
                </a>
              ) : (
                <a href="/">
                  Olá, Seja Bem-vindo
                  {/* <button type="button" onClick={signOut}>
                    Sair
                  </button> */}
                </a>
              )}
            </h3>
          </div>
        </div>
        <ContrastIcon
          onClick={toggleDarkMode}
          sx={{
            color: isDark
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            cursor: "pointer",
          }}
        />
      </div>
    </Header>
  );
};

export default HeaderComp;
