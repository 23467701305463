/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import * as React from "react";
import { Box, ListItemText } from "@mui/material";

const uninformed = "Não informado";

interface IItemText {
  primary?: any;
  hidePrimary?: boolean;
  secondary?: any;
  hideSecondary?: boolean;
  primarySx?: any;
  secondarySx?: any;
}

const ItemText: React.FC<IItemText> = ({
  primary,
  hidePrimary,
  secondary,
  hideSecondary,
  primarySx,
  secondarySx,
}) => {
  const primaryText = React.useCallback(() => {
    if (primary) return primary;
    if (!hidePrimary) return uninformed;
    // return ''
  }, [primary, hidePrimary]);
  const secondaryText = React.useCallback(() => {
    if (secondary) return secondary;
    if (!hideSecondary) return uninformed;
    // return ''
  }, [secondary, hideSecondary]);
  return (
    <ListItemText
      primaryTypographyProps={{
        component: Box,
        sx: primarySx,
      }}
      secondaryTypographyProps={{
        component: Box,
        sx: secondarySx,
      }}
      primary={primaryText()}
      secondary={secondaryText()}
    />
  );
};

export { ItemText };
