/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import { useHistory, useParams } from "react-router-dom";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { ItemText } from "../../../../components/itemText";
import theme from "../../../../theme";
import HeaderComp from "../../../../components/header";
import TicketingService from "../../../../services/ticketing";
import ListLogs from "./List";
import Loader from "../../../../components/Loader";
import { useToast } from "../../../../hooks/toast";
import ModalCancelar from "./ModalCancelar";
import TableLayout from "./Components/TableLayout";
import { ArrayFilters } from "../../../../interfaces/filterSearch";

const RechargeId: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLog, setLoadingLog] = useState<boolean>(true);
  const { push } = useHistory();
  const services = { ticketingService: new TicketingService() };
  const [dadosRecarga, setDadosRecarga] = useState(null);
  const [dadosLogs, setDadosLogs] = useState(null);
  const [primeirosTresDigitos, setPrimeirosTresDigitos] = useState(null);
  const { addToast } = useToast();
  const [modalOp, setModalOp] = useState(false);
  const [eventorec, setEventorec] = useState(false);
  const [alertando, setAlertando] = React.useState<boolean>(false);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{}],
  });
  const [page, setPage] = React.useState<number>(1);
  const [totalRows, setTotalRows] = React.useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);

  const handleChangePerPage = React.useCallback(
    async (newPage: number) => {
      setPage(newPage);
      setFilters({ filters: [...filters.filters, { page: newPage }] });
    },
    [filters, page]
  );

  const pageSize = useMediaQuery("(max-width:800px)");

  const getRechargeID = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.ticketingService.getRecargaId(id);
      setDadosRecarga(response?.data);
      setPrimeirosTresDigitos(
        response?.data?.card_data?.card_number?.substring(0, 3)
      );
    } catch (err: any) {
      const { ...error } = err;

      console.log(error);
      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const getRechargeLogs = useCallback(async () => {
    try {
      setLoadingLog(true);
      const response = await services.ticketingService.getRecargaLogs(
        filters,
        id
      );
      setTotalRows(response.data.count);
      const pages: any = response.data.count / 40;
      const resultado = Math.ceil(pages);
      setLastPage(resultado);
      setDadosLogs(response?.data);
    } catch (err: any) {
      const { ...error } = err;

      console.log(error);
      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoadingLog(false);
    }
  }, [page, filters]);

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.ticketingService.postRecargaUpdate(id);

      addToast({
        type: "success",
        title: `Requisição enviada com Sucesso.`,
        description: `Status Atual: ${
          response?.data?.detail
            ? response?.data?.detail
            : response?.data?.message
        }`,
      });
      getRechargeID();
    } catch (err: any) {
      const { ...error } = err;

      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handlCancelarQrCode = useCallback(async () => {
    try {
      setLoading(true);
      const response =
        await services.ticketingService.postCancelarRecargaQrCode(id);

      addToast({
        type: "success",
        title: `QrCode Cancelado com Sucesso.`,
        description: `${response?.data?.message}`,
      });
      setModalOp(false);
      getRechargeID();
    } catch (err: any) {
      const { ...error } = err;
      console.log(error);

      addToast({
        type: "error",
        title: `${error.response.data.message}`,
        description: `${error.response.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  function opencancel() {
    setModalOp(true);
  }

  function recebeevento(item: any) {
    setEventorec(item);
  }

  const direcionaRecarga = () => {
    push("/recargas");
  };

  useEffect(() => {
    getRechargeID();
  }, []);

  useEffect(() => {
    if (eventorec) {
      handlCancelarQrCode();
    }
  }, [eventorec]);

  useEffect(() => {
    if (dadosRecarga) {
      getRechargeLogs();
    }
  }, [dadosRecarga, page, filters]);

  useEffect(() => {
    if (totalRows > 15) {
      setAlertando(true);
    }
  }, [totalRows]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderComp filtroNaparece={true} />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "50px",
              justifyContent: alertando ? "center" : "center",
            }}
          >
            <ListItem
              component={LoadingButton}
              onClick={direcionaRecarga}
              sx={{
                borderRadius: "10px",
                width: pageSize ? "100px" : "150px",
                border: `1px solid ${theme.palette.secondary.main}`,
                marginRight: "10px",
                marginTop: "12px",
              }}
              className="buttons"
            >
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.main, marginRight: "5px" }}
              />
              <ItemText
                primary="VOLTAR"
                hideSecondary
                primarySx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  fontSize: pageSize ? "10px" : "14px",
                  borderRadius: "10px",
                }}
              />
            </ListItem>
            {alertando && (
              <Stack
                sx={{
                  marginLeft: "5%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p>
                  Pedido com{" "}
                  <strong style={{ color: theme.palette.secondary.main }}>
                    {totalRows} Logs
                  </strong>
                </p>
                <CrisisAlertIcon
                  sx={{
                    height: "50px",
                    width: "50px",
                    color: theme.palette.primary.main,
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                  className="updatedata"
                />
              </Stack>
            )}
          </Stack>
          <Box
            sx={{
              display: "flex",
              margin: 0,
            }}
          >
            <Container
              sx={{
                display: "flex",
                paddingBottom: "24px",
                paddingTop: "12px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  margin: 0,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflowX: "auto",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <Stack>
                        <ListSubheader
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="Dados da Recarga"
                              hideSecondary
                              primarySx={{
                                color: "white",
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "left-start",
                              }}
                            />
                          </Box>
                        </ListSubheader>
                      </Stack>
                      <TableLayout
                        label="Codigo Externo:"
                        value={dadosRecarga?.external_code}
                      />
                      <TableLayout
                        label="Origem:"
                        value={dadosRecarga?.origin}
                      />
                      <TableLayout
                        label="Origem Pagamento:"
                        value={dadosRecarga?.gateway_display}
                      />
                      <TableLayout
                        label="Status:"
                        value={dadosRecarga?.status_display}
                        isProcessing={[
                          "processing",
                          "waiting_payment_confirmation",
                          "payment_not_made",
                          "error",
                        ].includes(dadosRecarga?.status)}
                        handleUpdate={handleUpdate}
                      />
                      <TableLayout
                        label="User:"
                        value={dadosRecarga?.user_email}
                      />
                      <TableLayout
                        label="Cartão Transfacil:"
                        value={`${dadosRecarga?.card} / ${dadosRecarga?.card_data?.card_number}`}
                      />
                      <TableLayout
                        label="Forma de Pagamento:"
                        value={
                          dadosRecarga?.form_of_payment_data
                            ?.payment_type_display === "Boleto" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <p>{`${dadosRecarga?.form_of_payment} / ${dadosRecarga?.form_of_payment_data?.payment_type_display}`}</p>
                              <a
                                href={dadosRecarga?.tacom_boleto}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: theme.palette.primary.main,
                                  marginLeft: "10px",
                                }}
                              >
                                <DocumentScannerIcon
                                  sx={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </a>
                            </div>
                          ) : (
                            `${dadosRecarga?.form_of_payment} / ${dadosRecarga?.form_of_payment_data?.payment_type_display}`
                          )
                        }
                      />
                      <TableLayout
                        label="Recomendação Konduto:"
                        value={dadosRecarga?.konduto_recommendation}
                      />
                      <TableLayout
                        label="ID de Pagamento:"
                        value={dadosRecarga?.payment_id}
                      />
                      <TableLayout
                        label="Codigo da Transação:"
                        value={dadosRecarga?.transaction_code}
                      />
                      <TableLayout
                        label="ID Tacom:"
                        value={`${dadosRecarga?.tacom_order_id}`}
                      />
                      <TableLayout
                        label="Codigo NSU:"
                        value={dadosRecarga?.authorization_code}
                      />
                      <TableLayout
                        label="Valor:"
                        value={dadosRecarga?.amount}
                      />
                      <TableLayout
                        label="Data do Pedido:"
                        value={dadosRecarga?.created_at}
                      />
                      <TableLayout
                        label="Ultima Atualização:"
                        value={dadosRecarga?.updated_at}
                      />
                      {primeirosTresDigitos === "069" && (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <ListItemButton
                            component={LoadingButton}
                            sx={{
                              borderRadius: "10px",
                              width: pageSize ? "100px" : "200px",
                              cursor: "pointer",
                            }}
                          >
                            <ItemText
                              primary="CANCELAR QRCODE: "
                              hideSecondary
                              primarySx={{
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                                fontSize: pageSize ? "12px" : "16px",
                                borderRadius: "10px",
                              }}
                            />
                          </ListItemButton>
                          <ListItem
                            sx={{
                              borderRadius: "10px",
                              width: pageSize ? "100px" : "300px",
                              backgroundColor: theme.palette.secondary.main,
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                            onClick={opencancel}
                          >
                            <ItemText
                              primary={
                                <ClearIcon
                                  sx={{
                                    color: "white",
                                    marginRight: "5px",
                                  }}
                                />
                              }
                              secondary="Cancelar Carga"
                              primarySx={{
                                fontSize: pageSize ? "14px" : "18px",
                                fontWeight: "bold",
                                borderRadius: "10px",
                              }}
                              secondarySx={{
                                fontWeight: "bold",
                                color: "white",
                              }}
                            />
                          </ListItem>
                        </Stack>
                      )}
                    </Box>
                  </List>
                </Box>
                {modalOp && (
                  <ModalCancelar
                    open={modalOp}
                    handleClose={() => setModalOp(false)}
                    dadopedido={dadosRecarga?.external_code}
                    event={recebeevento}
                  />
                )}
                {loadingLog ? (
                  <Loader />
                ) : (
                  <ListLogs
                    list={dadosLogs}
                    totalRows={totalRows}
                    page={page}
                    lastPage={lastPage}
                    newpage={handleChangePerPage}
                  />
                )}
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default RechargeId;
