/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-multi-assign */
import React, { useState, useRef, useCallback, useEffect } from "react";

import * as Yup from "yup";

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LockOutlined } from "@mui/icons-material";
import { FiLock, FiUser } from "react-icons/fi";
import KeyIcon from "@mui/icons-material/Key";

import {
  isValidEmail,
  isValidCPF,
  formatCPF,
} from "@brazilian-utils/brazilian-utils";

import { Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { ItemText } from "../../components/itemText";
import theme from "../../theme";
import { useToast } from "../../hooks/toast";
import {
  Checkimg,
  Close,
  Company,
  Container,
  Content,
  Dialogo,
  Forgot,
  Modal,
  Register,
} from "./styles";
import { RadioButton } from "../../components/RadioButton";
import { useAuth } from "../../hooks/auth";
import AccountsService from "../../services/accounts";
import GreenCheck from "../../assets/Login/greencheck.svg";

import { useLoader } from "../../hooks/loader";
import getValidationErrors from "../../utils/getValidationErrors";
import ButtonStyled from "../../components/Button";

import api from "../../services/api";
import DashBoardLoader from "../../components/DashBoardLoader";
import Input from "../../components/Input";

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefReset = useRef<FormHandles>(null);
  const servicesProfile = { AccountsService: new AccountsService() };
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { isActive, changeLoader } = useLoader();
  const [isemail, setIsemail] = useState(null);
  const [finish, setFinish] = useState(false);
  const [modalOp, setModalOp] = useState(false);
  const [checkfinish, setCheckfinish] = useState(false);
  const [buttonchange, setButtonChange] = useState("#FFC4AE");

  interface ProfileReset {
    id: number;
    email: string;
    phone: string;
  }

  const [resetpassword, setResetPassword] = useState<ProfileReset>(
    {} as ProfileReset
  );

  const schema = Yup.object().shape({
    user: Yup.string()
      .test("login-validation", "Email ou CPF inválido", (value) => {
        const isCpf = isValidCPF(value);
        if (!isCpf) {
          const isEmail = isValidEmail(value);
          if (!isEmail) {
            return false;
          }
          if (isEmail) {
            return true;
          }
        }
        if (isCpf) {
          const formatacpf = formatCPF(value, { pad: true });
          const myInput = document.getElementById("login") as HTMLInputElement;
          myInput.value = formatacpf;
          return true;
        }
        return false;
      })
      .required("Email ou CPF é obrigatório"),
    password: Yup.string().required("Senha é obrigatória"),
  });

  const schemaReset = Yup.object().shape({
    // eslint-disable-next-line camelcase
    email_or_cpf: Yup.string()
      .test("login-validation", "Email ou CPF inválido", (value) => {
        const isCpf = isValidCPF(value);
        if (!isCpf) {
          const isEmail = isValidEmail(value);
          setButtonChange("#FFC4AE");
          if (!isEmail) {
            setButtonChange("#FFC4AE");
            return false;
          }
          if (isEmail) {
            setButtonChange("#Eb5e2b");
            return true;
          }
        }
        if (isCpf) {
          const formatacpf = formatCPF(value, { pad: true });
          const myInput = document.getElementById(
            "email_or_cpf"
          ) as HTMLInputElement;
          myInput.value = formatacpf;
          setButtonChange("#Eb5e2b");
          return true;
        }
        return false;
      })
      .required("Email ou CPF é obrigatório"),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        changeLoader(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          user: data.user,
          password: data.password,
        });
        changeLoader(false);
      } catch (err) {
        const { ...error }: any = err;

        console.log({ err });

        changeLoader(false);

        let migrated_user = false;

        if (error.response) {
          migrated_user = error.response.data.migrated_user;
        }

        if (migrated_user) {
          const modal = document.getElementById("migrateduser");
          modal.style.display = "block";

          await api.post("/accounts/reset-password/", {
            user: data.user,
          });

          addToast({
            type: "success",
            title: "E-mail enviado",
            description: "E-mail enviado com sucesso!!",
          });
        } else {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
            return;
          }
          addToast({
            type: "error",
            title: `${
              error?.response?.data?.message
                ? error?.response?.data?.message
                : "Ocorreu um erro ao realizar o Login"
            }`,
            description: `${
              error?.response?.data?.sub_message
                ? error?.response?.data?.sub_message
                : "Verifique login e senha ou entre em contato com o Administrador"
            }`,
          });
        }
      }
    },
    [schema, signIn, addToast, changeLoader]
  );

  const handleSubmitForgot = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        await schemaReset.validate(data, {
          abortEarly: false,
        });
        const resetpass =
          await servicesProfile.AccountsService.GetresetPassword(
            data.email_or_cpf
          );

        setResetPassword(resetpass.data);
        setIsemail(true);
        setButtonChange("#FFC4AE");
      } catch (err) {
        const { ...error }: any = err;
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: "error",
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Ocorreu um erro para recuperar a senha"
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : "Verifique se o email ou cpf informados estão corretos ou entre em contato com o Administrador"
          }`,
        });
      }
    },
    [schemaReset, servicesProfile]
  );

  const handleSubmitCheckbox = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});

        const mailorphone = data.RadioButton;

        await api.post("/v2/accounts/reset-password/", {
          id: resetpassword.id,
          reset_type: mailorphone === resetpassword.phone ? "sms" : "email",
        });

        if (mailorphone === resetpassword?.phone) {
          setFinish(true);
          setCheckfinish(false);
        } else {
          setFinish(true);
          setCheckfinish(true);
        }
      } catch (err) {
        const { ...error }: any = err;

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: "error",
          title: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
          description: `${
            error.response.data.message
              ? error.response.data.message
              : error.response.data.detail
          }`,
        });
      }
    },
    [addToast, finish, checkfinish, resetpassword]
  );

  function openforgot() {
    const modal: any = document.getElementById("forgot_password");
    setIsemail(false);
    setModalOp(true);
    modal.style.display = "block";
  }

  function closeforgot() {
    const modal: any = document.getElementById("forgot_password");
    setFinish(false);
    setModalOp(false);
    modal.style.display = "none";
  }

  useEffect(() => {
    const email = formRef.current.getFieldValue("email");
    if (email) {
      setIsemail(true);
    } else {
      setIsemail(false);
    }
  }, []);

  useEffect(() => {
    const checkboxmail = formRef.current.getFieldValue("");
    if (checkboxmail) {
      setCheckfinish(true);
    }
    if (!checkboxmail) {
      const checkboxphone = formRef.current.getFieldValue("");
      if (checkboxphone) {
        setCheckfinish(false);
      } else {
        setCheckfinish(true);
      }
    }
  }, []);

  return (
    <Container>
      {isActive && <DashBoardLoader />}
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!modalOp && (
          <Stack flexDirection="row" alignItems="center">
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
                minWidth: "320px",
              }}
            >
              <Box>
                <ItemText
                  primary="Transfácil Admin"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "32px",
                  }}
                  secondarySx={{
                    fontSize: "24px",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
        )}
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            boxShadow: "0 2px 4px 0 rgb(0 0 0 / 40%)",
            borderRadius: "10px",
            padding: "20px",
            marginTop: "-10px",
            minWidth: "320px",
          }}
        >
          <Input
            id="login"
            name="user"
            icon={FiUser}
            className="input"
            placeholder="E-mail ou CPF"
            schema={schema}
          />
          <Input
            id="password"
            name="password"
            icon={FiLock}
            className="password"
            type="password"
            placeholder="Senha"
            schema={schema}
          />

          <ButtonStyled
            color="orange"
            padding="5"
            type="submit"
            className="entrar"
          >
            Entrar
          </ButtonStyled>

          <ButtonStyled
            color="orange"
            padding="5"
            onClick={openforgot}
            className="forgpassword"
          >
            Esqueceu sua Senha
          </ButtonStyled>
        </Form>
        <Modal id="forgot_password">
          <Dialogo>
            {finish ? (
              checkfinish ? (
                <div className="containersent">
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <Checkimg>
                      <img
                        src={GreenCheck}
                        alt="CheckIMG"
                        className="greencheck"
                      />
                    </Checkimg>
                    <h1 className="password">
                      Enviaremos um link para o seu e-mail cadastrado
                    </h1>
                    <p
                      style={{
                        marginBottom: "10px",
                        marginRight: "-30px",
                      }}
                    >
                      Acesse seu e-mail {resetpassword?.email} para alterar a
                      senha.
                    </p>
                    <div className="email">
                      <span className="button">
                        <ButtonStyled
                          color="orange"
                          padding=""
                          rounded
                          onClick={closeforgot}
                          className="enviar"
                        >
                          Concluir
                        </ButtonStyled>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="containersent">
                  <Close onClick={closeforgot}>X</Close>
                  <div className="text">
                    <Checkimg>
                      <img
                        src={GreenCheck}
                        alt="CheckIMG"
                        className="greencheck"
                      />
                    </Checkimg>
                    <h1 className="password">
                      Enviaremos um link para o seu telefone cadastrado
                    </h1>
                    <p
                      style={{
                        marginBottom: "10px",
                        marginRight: "-30px",
                      }}
                    >
                      Verifique seu telefone {resetpassword?.phone} para alterar
                      a senha.
                    </p>
                    <div className="email">
                      <span className="button">
                        <ButtonStyled
                          color="orange"
                          padding=""
                          rounded
                          onClick={closeforgot}
                          className="enviar"
                        >
                          Concluir
                        </ButtonStyled>
                      </span>
                    </div>
                  </div>
                </div>
              )
            ) : isemail ? (
              <div
                className="checkcontainer"
                style={{
                  marginBottom: "10px",
                }}
              >
                <Close onClick={closeforgot}>X</Close>
                <div className="text">
                  <h1 className="password">
                    Por onde você prefere receber o link de alteração de senha?
                  </h1>
                  <Form
                    ref={formRefReset}
                    onSubmit={handleSubmitCheckbox}
                    placeholder="E-mail"
                    method="post"
                    className="checkbox"
                  >
                    <RadioButton
                      name="RadioButton"
                      options={[
                        {
                          id: "EmailChecked",
                          value: resetpassword?.email,
                          label: "Email",
                        },
                        {
                          id: "PhoneChecked",
                          value: resetpassword?.phone,
                          label: "Telefone",
                        },
                      ]}
                    />
                    <p
                      style={{
                        marginTop: "130px",
                        marginBottom: "40px",
                      }}
                    >
                      Caso não tenha acesso ao email ou telefone citados acima,
                      favor entrar em contato com o nosso SAC (31) 3248-7300.
                    </p>
                    <span className="button">
                      <ButtonStyled
                        color="orange"
                        padding=""
                        rounded
                        type="submit"
                        className="enviar"
                      >
                        Concluir
                      </ButtonStyled>
                      <ButtonStyled
                        color="white"
                        padding=""
                        rounded
                        type="button"
                        className="cancelar"
                        onClick={closeforgot}
                      >
                        Cancelar
                      </ButtonStyled>
                    </span>
                  </Form>
                </div>
              </div>
            ) : (
              <div className="containersent">
                <Close onClick={closeforgot}>X</Close>
                <div className="text">
                  <h1 className="password">Esqueceu a senha?</h1>
                  <p>Insira o Email ou CPF associado à conta.</p>
                  <Form
                    ref={formRefReset}
                    onSubmit={handleSubmitForgot}
                    placeholder="E-mail"
                    method="post"
                    className="email"
                  >
                    <span>
                      <Input
                        id="email_or_cpf"
                        name="email_or_cpf"
                        icon={FiUser}
                        className="email"
                        placeholder="Email ou CPF"
                        schema={schemaReset}
                      />
                    </span>
                    <span className="button">
                      <ButtonStyled
                        style={{
                          backgroundColor: `${buttonchange}`,
                        }}
                        color="#FFC4AE"
                        padding=""
                        rounded
                        type="submit"
                        className="enviar"
                      >
                        Próximo Passo
                      </ButtonStyled>
                    </span>
                  </Form>
                </div>
              </div>
            )}
          </Dialogo>
        </Modal>
        <Register>
          <p>
            Não tem conta?
            <strong>
              <Link to="/cadastrar">Cadastrar</Link>
            </strong>
          </p>
        </Register>
      </Content>
      {/* <AcceptCookies /> */}
    </Container>
    // <Box
    //   sx={{
    //     ,
    //     height: "100vh",
    //     display: "flex",
    //     width: "100%",
    //     margin: 0,
    //     alignItems: "center",
    //   }}
    // >
    //   {isActive && <DashBoardLoader />}
    //   <Container
    //     sx={{
    //       display: "flex",
    //       justifyContent: "center",
    //       paddingBottom: "24px",
    //       paddingTop: "24px",
    //     }}
    //   >
    //     <List
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           width: "100%",
    //           backgroundColor: "white",
    //           borderRadius: 4,
    //           boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    //           minWidth: "300px",
    //         }}
    //       >
    //         <Stack flexDirection="row" alignItems="center">
    //           <ListSubheader
    //             sx={{
    //               backgroundColor: theme.palette.secondary.main,
    //               borderTopLeftRadius: 10,
    //               borderTopRightRadius: 10,
    //               width: "100%",
    //               textAlign: "center",
    //             }}
    //           >
    //             <Box>
    //               <ItemText
    //                 primary="Transfácil Admin"
    //                 hideSecondary
    //                 primarySx={{
    //                   color: "white",
    //                   fontWeight: "bold",
    //                   fontSize: "32px",
    //                 }}
    //                 secondarySx={{
    //                   fontSize: "24px",
    //                 }}
    //               />
    //             </Box>
    //           </ListSubheader>
    //         </Stack>
    //         <ListItem>
    //           <ItemText
    //             primary="Email"
    //             secondary={<TextField name="email" fullWidth />}
    //           />
    //         </ListItem>
    //         <ListItem>
    //           <ItemText
    //             primary="Senha"
    //             secondary={
    //               <TextField name="password" type="password" fullWidth />
    //             }
    //           />
    //         </ListItem>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //           }}
    //         >
    //           <ListItemButton
    //             component={LoadingButton}
    //             type="submit"
    //             sx={{
    //               mt: 3,
    //               mb: 2,
    //               borderRadius: "10px",
    //               backgroundColor: theme.palette.secondary.main,
    //               color: "white",
    //             }}
    //           >
    //             <Link to="/">
    //               <ListItemIcon sx={{ minWidth: "35px", color: "white" }}>
    //                 <LockOutlined />
    //               </ListItemIcon>
    //               <ItemText primary="Acessar" hideSecondary />
    //             </Link>
    //           </ListItemButton>
    //           <ListItemButton
    //             component={Button}
    //             sx={{
    //               backgroundColor: theme.palette.secondary.main,
    //               borderRadius: "10px",
    //               mb: 2,
    //               color: "white",
    //             }}
    //             onClick={() => "/forgotpassword"}
    //           >
    //             <ListItemIcon sx={{ minWidth: "35px", color: "white" }}>
    //               <KeyIcon />
    //             </ListItemIcon>
    //             <ItemText primary="Esqueceu sua senha?" hideSecondary />
    //           </ListItemButton>
    //         </Box>
    //       </Box>
    //     </List>
    //     <Modal id="forgot_password">
    //       <Dialogo>
    //         {finish ? (
    //           checkfinish ? (
    //             <div className="containersent">
    //               <Close onClick={closeforgot}>X</Close>
    //               <div className="text">
    //                 <Checkimg>
    //                   <img
    //                     src={GreenCheck}
    //                     alt="CheckIMG"
    //                     className="greencheck"
    //                   />
    //                 </Checkimg>
    //                 <h1 className="password">
    //                   Enviaremos um link para o seu e-mail cadastrado
    //                 </h1>
    //                 <p
    //                   style={{
    //                     marginBottom: "10px",
    //                     marginRight: "-30px",
    //                   }}
    //                 >
    //                   Acesse seu e-mail {resetpassword?.email} para alterar a
    //                   senha.
    //                 </p>
    //                 <div className="email">
    //                   <span className="button">
    //                     <ButtonStyled
    //                       color="orange"
    //                       padding=""
    //                       rounded
    //                       onClick={closeforgot}
    //                       className="enviar"
    //                     >
    //                       Concluir
    //                     </ButtonStyled>
    //                   </span>
    //                 </div>
    //               </div>
    //             </div>
    //           ) : (
    //             <div className="containersent">
    //               <Close onClick={closeforgot}>X</Close>
    //               <div className="text">
    //                 <Checkimg>
    //                   <img
    //                     src={GreenCheck}
    //                     alt="CheckIMG"
    //                     className="greencheck"
    //                   />
    //                 </Checkimg>
    //                 <h1 className="password">
    //                   Enviaremos um link para o seu telefone cadastrado
    //                 </h1>
    //                 <p
    //                   style={{
    //                     marginBottom: "10px",
    //                     marginRight: "-30px",
    //                   }}
    //                 >
    //                   Verifique seu telefone {resetpassword?.phone} para alterar
    //                   a senha.
    //                 </p>
    //                 <div className="email">
    //                   <span className="button">
    //                     <ButtonStyled
    //                       color="orange"
    //                       padding=""
    //                       rounded
    //                       onClick={closeforgot}
    //                       className="enviar"
    //                     >
    //                       Concluir
    //                     </ButtonStyled>
    //                   </span>
    //                 </div>
    //               </div>
    //             </div>
    //           )
    //         ) : isemail ? (
    //           <div
    //             className="checkcontainer"
    //             style={{
    //               marginBottom: "10px",
    //             }}
    //           >
    //             <Close onClick={closeforgot}>X</Close>
    //             <div className="text">
    //               <h1 className="password">
    //                 Por onde você prefere receber o link de alteração de senha?
    //               </h1>
    //               <Form
    //                 ref={formRefReset}
    //                 onSubmit={handleSubmitCheckbox}
    //                 placeholder="E-mail"
    //                 method="post"
    //               >
    //                 <RadioButton
    //                   name="RadioButton"
    //                   options={[
    //                     {
    //                       id: "EmailChecked",
    //                       value: resetpassword?.email,
    //                       label: "Email",
    //                     },
    //                     {
    //                       id: "PhoneChecked",
    //                       value: resetpassword?.phone,
    //                       label: "Telefone",
    //                     },
    //                   ]}
    //                 />
    //                 <p
    //                   style={{
    //                     marginTop: "130px",
    //                     marginBottom: "40px",
    //                   }}
    //                 >
    //                   Caso não tenha acesso ao email ou telefone citados acima,
    //                   favor entrar em contato com o nosso SAC (31) 3248-7300.
    //                 </p>
    //                 <span className="button">
    //                   <ButtonStyled
    //                     color="orange"
    //                     padding=""
    //                     rounded
    //                     type="submit"
    //                     className="enviar"
    //                   >
    //                     Concluir
    //                   </ButtonStyled>
    //                   <ButtonStyled
    //                     color="white"
    //                     padding=""
    //                     rounded
    //                     type="button"
    //                     className="cancelar"
    //                     onClick={closeforgot}
    //                   >
    //                     Cancelar
    //                   </ButtonStyled>
    //                 </span>
    //               </Form>
    //             </div>
    //           </div>
    //         ) : (
    //           <div className="containersent">
    //             <Close onClick={closeforgot}>X</Close>
    //             <div className="text">
    //               <h1 className="password">Esqueceu a senha?</h1>
    //               <p>Insira o Email ou CPF associado à conta.</p>
    //               <Form
    //                 ref={formRefReset}
    //                 onSubmit={handleSubmitForgot}
    //                 placeholder="E-mail"
    //                 method="post"
    //                 className="email"
    //               >
    //                 <span>
    //                   <InputStyled
    //                     id="email_or_cpf"
    //                     name="email_or_cpf"
    //                     icon={FiUser}
    //                     className="email"
    //                     placeholder="Email ou CPF"
    //                     schema={schemaReset}
    //                   />
    //                 </span>
    //                 <span className="button">
    //                   <ButtonStyled
    //                     style={{
    //                       backgroundColor: `${buttonchange}`,
    //                     }}
    //                     color="#FFC4AE"
    //                     padding=""
    //                     rounded
    //                     type="submit"
    //                     className="enviar"
    //                   >
    //                     Próximo Passo
    //                   </ButtonStyled>
    //                 </span>
    //               </Form>
    //             </div>
    //           </div>
    //         )}
    //       </Dialogo>
    //     </Modal>
    //   </Container>
    // </Box>
  );
};

export default SignIn;
