/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable from "react-data-table-component";
import { Container } from "./styles";

interface ITable {
  columns: any[];
  data: any[];
  title?: any;
}

const CardsTable: React.FC<ITable> = ({ columns, data, title }) => {
  const customColumns = columns.map((col: any) => {
    if (col.name === "Linha") {
      return { ...col, width: "50px" }; // Ajuste a largura conforme necessário
    }
    return col;
  });
  return (
    <Container>
      <DataTable columns={customColumns} data={data} title={title} />
    </Container>
  );
};

export { CardsTable };
