/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Home from "../pages/Home";
import Recharge from "../pages/Ticketing/Recharge/index";
import MeuPerfil from "../pages/MeuPerfil";
import SignIn from "../pages/SignIn";

import Register from "../pages/Register";
import RechargeId from "../pages/Ticketing/Recharge/RechargeId";

import Cards from "../pages/Ticketing/Cards";
import CardId from "../pages/Ticketing/Cards/CardID";
import BannersMobile from "../pages/Core/BannersMobile";
import BannerMobId from "../pages/Core/BannersMobile/BannerID";
import BannersWeb from "../pages/Core/BannersWeb";
import BannerWebId from "../pages/Core/BannersWeb/BannerID";
import BannerMobIdNew from "../pages/Core/BannersMobile/BannerIDNew";
import BannerWebIdNew from "../pages/Core/BannersWeb/BannerIDNew";
import Settings from "../pages/Settings/Configuracores";
import AppVersion from "../pages/Core/AppVersion";
import AppVersionNew from "../pages/Core/AppVersion/AppVersionNew";
import AppVersionId from "../pages/Core/AppVersion/AppVersionID";

const routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route path="/cadastrar" component={Register} />
        <PrivateRoute path="/" exact component={Home} />

        <PrivateRoute exact path="/bannermob/:id" component={BannerMobId} />
        <PrivateRoute exact path="/bannermobnew" component={BannerMobIdNew} />
        <PrivateRoute path="/bannermob" component={BannersMobile} />

        <PrivateRoute exact path="/bannerweb/:id" component={BannerWebId} />
        <PrivateRoute exact path="/bannerwebnew" component={BannerWebIdNew} />
        <PrivateRoute path="/bannerweb" component={BannersWeb} />

        <PrivateRoute path="/appversion" component={AppVersion} />
        <PrivateRoute path="/appversionnew" component={AppVersionNew} />
        <PrivateRoute path="/app-version/:id" component={AppVersionId} />

        <PrivateRoute exact path="/recargas/:id" component={RechargeId} />
        <PrivateRoute path="/recargas" component={Recharge} />
        <PrivateRoute exact path="/cards/:id" component={CardId} />
        <PrivateRoute path="/cards" component={Cards} />
        <PrivateRoute exact path="/meu-perfil" component={MeuPerfil} />

        <PrivateRoute path="/settings" component={Settings} />
      </Switch>
    </>
  );
};

export default routes;
