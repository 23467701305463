/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";

import * as Yup from "yup";

import { Box, List, ListSubheader, ListItemButton, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ItemText } from "../../../itemText";
import theme from "../../../../theme";

interface AccountsProps {
  aside?: any;
  tipoStatus?: any;
}

const PorStatus: React.FC<AccountsProps> = ({ aside, tipoStatus }) => {
  const dadosget = localStorage.getItem("RecargaStatus");
  const [selectedItem, setSelectedItem] = React.useState("todos");
  const [selectedStatus, setSelectedStatus] = React.useState(dadosget);

  const handleStatus = (item: any, name: any, origem: any) => {
    tipoStatus(item ? { value: item, label: name, fOrigem: "status" } : "null");
  };

  const Dados = [
    { value: "todos", name: "Todos", origem: "status" },
    { value: "pending", name: "Aguardando Processamento", origem: "status" },
    { value: "processing", name: "Em Processamento", origem: "status" },
    { value: "antifraud_denial", name: "Pedido Negado", origem: "status" },
    { value: "error", name: "Erro no Processamento", origem: "status" },
    {
      value: "waiting_payment_confirmation",
      name: "Aguardando Confirmação de Pagamento",
      origem: "status",
    },
    {
      value: "waiting_boleto_payment",
      name: "Aguardando Pagamento de Boleto",
      origem: "status",
    },
    {
      value: "payment_confirmed",
      name: "Pagamento Confirmado Estocando Crédito",
      origem: "status",
    },
    {
      value: "payment_not_made",
      name: "Pagamento não realizado",
      origem: "status",
    },
    {
      value: "sent_for_stock",
      name: "Pedido Enviado para Estocagem",
      origem: "status",
    },
    {
      value: "processed_success",
      name: "Liberado! Valide sua carga dentro dos ônibus",
      origem: "status",
    },
    { value: "stock_started", name: "Estocagem Iniciada", origem: "status" },
    { value: "stock_error", name: "Erro ao estocar cartão", origem: "status" },
    {
      value: "stock_order_created",
      name: "Pedido de Estocagem Criado",
      origem: "status",
    },
    { value: "processed_denied", name: "Pagamento Recusado", origem: "status" },
    { value: "retry", name: "Tentar Novamente", origem: "status" },
    {
      value: "bhbus_card_with_daily_recharge",
      name: "Cartão com Recarga no Dia Atual",
      origem: "status",
    },
    {
      value: "bhbus_card_near_expiration",
      name: "Cartão Próximo da Data de Validade",
      origem: "status",
    },
    {
      value: "with_more_tacom_orders",
      name: "Com Mais Pedidos Tacom",
      origem: "status",
    },
    { value: "canceled", name: "Cancelado", origem: "status" },
  ];

  const handleItemClick = useCallback(
    async (item: any, name: any, origem: any) => {
      localStorage.removeItem("RecargaStatus");
      setSelectedItem(item);

      if (item === "todos") {
        localStorage.removeItem("RecargaStatus");
      } else {
        localStorage.setItem("RecargaStatus", `${item}`);
      }
      handleStatus(item, name, origem);
    },
    []
  );

  useEffect(() => {
    if (selectedStatus !== null) {
      setSelectedItem(selectedStatus);
    }
  }, [selectedStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            minWidth: "250px",
            maxWidth: "250px",
          }}
        >
          <Stack>
            <ListSubheader
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <ItemText
                  primary="Status"
                  hideSecondary
                  primarySx={{
                    color: "white",
                    fontSize: "16px",
                    textAlign: "left-start",
                  }}
                />
              </Box>
            </ListSubheader>
          </Stack>
          <Stack>
            {selectedItem &&
              Dados.map((item) => (
                <ListItemButton
                  key={item.value}
                  component={LoadingButton}
                  onClick={() =>
                    handleItemClick(item.value, item.name, item?.origem)
                  }
                  sx={{
                    backgroundColor:
                      selectedItem === item.value ? "#482A73" : "white",
                    borderRadius: "10px",
                  }}
                >
                  <ItemText
                    primary={item.name}
                    hideSecondary
                    primarySx={{
                      color:
                        selectedItem === item.value
                          ? "white"
                          : theme.palette.secondary.main,
                      fontWeight: "bold",
                      fontSize: "12px",
                      backgroundColor:
                        selectedItem === item.value ? "#482A73" : "white",
                      borderRadius: "10px",
                    }}
                  />
                </ListItemButton>
              ))}
          </Stack>
        </Box>
      </List>
    </Box>
  );
};

export default PorStatus;
