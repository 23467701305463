import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--purple);
  font-weight: bold;
`;

export const InputDate = styled.input`
  padding: 8px;
  border: 1px solid var(--purple);
  border-radius: 4px;
  font-size: 16px;
`;

export const ErrorSpan = styled.span`
  color: red;
  margin-top: 4px;
  font-size: 12px;
`;
