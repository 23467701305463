/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
// FilterRangeCalendar.tsx
import React from "react";
import { useField } from "@unform/core";
import { Container, ErrorSpan, InputDate, Label } from "./styles";

interface FilterRangeCalendarProps {
  name: string;
  label?: string;
}

const FilterCalendarDateTime: React.FC<FilterRangeCalendarProps> = ({
  name,
  label,
}) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const convertToDateTimeLocal = (dateString: string): string => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    // Cria um objeto Date
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const formattedDate = [
      date.getFullYear(),
      String(date.getMonth() + 1).padStart(2, "0"),
      String(date.getDate()).padStart(2, "0"),
    ].join("-");

    const formattedTime = [
      String(date.getHours()).padStart(2, "0"),
      String(date.getMinutes()).padStart(2, "0"),
      String(date.getSeconds()).padStart(2, "0"),
    ].join(":");

    return `${formattedDate}T${formattedTime}`;
  };

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Label htmlFor={fieldName}>{label}</Label>
      <InputDate
        ref={inputRef}
        defaultValue={
          defaultValue ? convertToDateTimeLocal(defaultValue) : null
        }
        id={fieldName}
        step={1}
        type="datetime-local"
        style={{ color: "var(--purple)", fontWeight: "bold" }}
      />
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </Container>
  );
};

export default FilterCalendarDateTime;
