/* eslint-disable @typescript-eslint/no-use-before-define */
// FilterRangeCalendar.tsx
import React from "react";
import { useField } from "@unform/core";
import { Container, ErrorSpan, InputDate, Label } from "./styles";

interface FilterRangeCalendarProps {
  name: string;
  label: string;
}

const FilterRangeCalendar: React.FC<FilterRangeCalendarProps> = ({
  name,
  label,
}) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Label htmlFor={fieldName}>{label}</Label>
      <InputDate
        ref={inputRef}
        defaultValue={defaultValue}
        id={fieldName}
        type="datetime-local"
        style={{ color: "var(--purple)", fontWeight: "bold" }}
      />
      {error && <ErrorSpan>{error}</ErrorSpan>}
    </Container>
  );
};

export default FilterRangeCalendar;
