/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useRef, useEffect, useCallback, useState } from "react";
import * as Yup from "yup";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { useField } from "@unform/core";

import { Container, Error, Label } from "./styles";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
  schema?: Yup.ObjectSchema;
}
const Select: React.FC<Props> = ({ name, label, schema, ...rest }) => {
  const selectRef = useRef<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    try {
      const isValid = await Yup.reach(
        schema || ({} as Yup.ObjectSchema),
        fieldName
      ).validate(selectRef.current?.state.value?.value, { abortEarly: true });
      setIsCorrect(!!isValid);
    } catch (err) {
      setIsCorrect(false);
    }
  }, [schema, fieldName]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Container
        isErrored={!!error}
        isFocused={isFocused}
        isCorrect={isCorrect}
      >
        <ReactSelect
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          {...rest}
        />
      </Container>
      {!isCorrect && <Error>{error}</Error>}
    </>
  );
};
export default Select;
