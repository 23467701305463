/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosPromise } from "axios";
import api from "./api";
import { Filters, RechargeFilter } from "../interfaces/recharge";

interface Card {
  id: number;
  user: string;
  nickname: string;
  card_number: string;
  createdAt: Date;
  balance: number;
}

interface Recarga {
  id: number;
  created_at: string;
  amount: number;
  status_display: string;
  tacom_boleto: string;
  pix_copy_paste: string;
  pix_expiration: string;
  pix_image_link: string;
  card_data: { nickname: string; card_number: string };
}

const ConvertArrayFilterToObject = (filters: Filters = {}): RechargeFilter => {
  try {
    const object = filters.reduce(
      (obj: any, item: any) => ({ ...obj, ...item }),
      {}
    );
    return object;
  } catch (err) {
    return {};
  }
};

const SerializeFilters = (filters: any): string => {
  const str = [];
  for (const p in filters) {
    console.log(p);
    if (p === "date_before" || p === "date_after") {
      str.push(`${p}=${filters[p]}`); // Não codifica esses valores
    } else {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(filters[p])}`);
    }
  }
  return str.join("&");
};

class TicketingService {
  getCards = (filters?: Filters): AxiosPromise => {
    const convertedFilters = ConvertArrayFilterToObject(filters.filters);

    const url =
      Object.keys(convertedFilters).length === 0
        ? "ticketing/manager/recharge-request/?page=1"
        : `ticketing/manager/recharge-request/?${SerializeFilters(
            convertedFilters
          )}`;
    return api.get(url);
  };

  getRecarga = (filters?: Filters): AxiosPromise => {
    const convertedFilters = ConvertArrayFilterToObject(filters);
    console.log(convertedFilters);
    console.log(SerializeFilters(convertedFilters));
    const url =
      Object.keys(convertedFilters).length === 0
        ? "ticketing/manager/recharge-request/?page=1"
        : `ticketing/manager/recharge-request/?${SerializeFilters(
            convertedFilters
          )}`;
    return api.get(url);
  };

  getRecargaId = (id: string): AxiosPromise => {
    const url = `ticketing/manager/recharge-request/${id}/`;
    return api.get(url);
  };

  getRecargaLogs = (filters?: Filters, id?: string): AxiosPromise => {
    const convertedFilters = ConvertArrayFilterToObject(filters.filters);
    const url =
      Object.keys(convertedFilters).length === 0
        ? `ticketing/manager/recharge-request-log/?recharge_id=${id}`
        : `ticketing/manager/recharge-request-log/?recharge_id=${id}&${SerializeFilters(
            convertedFilters
          )}`;
    return api.get(url);
  };

  postRecargaUpdate = (id: string): AxiosPromise => {
    const url = `ticketing/manager/recharge-request/${id}/check/`;
    return api.post(url);
  };

  postCancelarRecargaQrCode = (id: string): AxiosPromise => {
    const url = `ticketing/manager/recharge-request/${id}/cancel-recharge/`;
    return api.post(url);
  };
}

export type { Card, Recarga };

export default TicketingService;
