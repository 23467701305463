/* eslint-disable react/jsx-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from "react";

import {
  Box,
  List,
  ListSubheader,
  Stack,
  ListItemButton,
  useMediaQuery,
  ListItem,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ItemText } from "../../../../../components/itemText";
import theme from "../../../../../theme";

interface Props {
  list?: any;
  page?: number;
  totalRows?: number;
  newpage?: any;
  lastPage?: any;
}

const ListLogs: React.FC<Props> = ({
  list,
  page,
  totalRows,
  newpage,
  lastPage,
}) => {
  const pageSize = useMediaQuery("(max-width:1000px)");

  const handleChangePerPage = React.useCallback(async (newPage: number) => {
    newpage(newPage);
  }, []);

  return (
    <Box
      sx={{
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "auto",
        marginLeft: "2%",
        marginRight: "2%",
      }}
    >
      <List
        sx={{
          width: "90vw",
          height: "100%",
        }}
      >
        <ListSubheader
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: "90vw",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          <ItemText
            primary="Lista de Logs"
            hideSecondary
            primarySx={{
              color: "white",
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "left-start",
            }}
          />
        </ListSubheader>

        {list?.results?.map((dados: any, index: number) => (
          <Box
            key={index}
            sx={{
              width: "90vw",
              backgroundColor: "white",
              borderRadius: 4,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              marginBottom: "16px",
            }}
          >
            <Stack>
              {Object.entries(dados).map(([key, value], innerIndex) => (
                <ListItem
                  sx={{
                    borderRadius: "10px",
                    marginBottom: "8px",
                    overflowX: "auto",
                    width: "90vw",
                  }}
                >
                  <ListItem
                    component={LoadingButton}
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "8px",
                      height: "100%",
                      width: "100px",
                      minWidth: "100px",
                    }}
                    key={innerIndex}
                  >
                    <ItemText
                      primary={`${key}: `}
                      hideSecondary
                      primarySx={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                        fontSize: pageSize ? "12px" : "16px",
                        borderRadius: "10px",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "8px",
                      height: "100%",
                      marginLeft: "20px",
                    }}
                    key={innerIndex}
                  >
                    <ItemText
                      primary={
                        value !== null &&
                        typeof value === "object" &&
                        "details" in value
                          ? value.details
                            ? JSON.stringify(value.details)
                            : JSON.stringify(value)
                          : value !== null &&
                            typeof value === "object" &&
                            "accounts" in value
                          ? JSON.stringify(value.accounts)
                          : JSON.stringify(value)
                      }
                      hideSecondary
                      primarySx={{
                        fontSize: pageSize ? "10px" : "14px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        color: theme.palette.secondary.main,
                      }}
                    />
                  </ListItem>
                </ListItem>
              ))}
            </Stack>
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
            marginBottom: 2,
            fontWeight: "bold",
          }}
        >
          <Button
            disabled={list?.previous === null}
            onClick={() => handleChangePerPage(1)}
            sx={{
              backgroundColor: theme.palette.primary.main,
              margin: 1,
              color: "white",
            }}
          >
            Primeira Página
          </Button>
          <Button
            disabled={list?.previous === null}
            onClick={() => handleChangePerPage(page - 1)}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              margin: 1,
              color: "white",
            }}
          >
            Anterior
          </Button>
          <Box sx={{ margin: 2 }}>{`Página ${page} de ${lastPage}`}</Box>
          <Button
            disabled={list?.next === null}
            onClick={() => handleChangePerPage(page + 1)}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              margin: 1,
              color: "white",
            }}
          >
            Próxima
          </Button>
          <Button
            disabled={list?.next === null}
            onClick={() => handleChangePerPage(lastPage)}
            sx={{
              backgroundColor: theme.palette.primary.main,
              margin: 1,
              color: "white",
            }}
          >
            Última Página
          </Button>
        </Box>
      </List>
    </Box>
  );
};

export default ListLogs;
