import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  overflow: hidden;
  z-index: 99999999999998;
`;
