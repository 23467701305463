/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { Box, Container, ListItem } from "@mui/material";

import { ItemText } from "../components/itemText";
import theme from "../theme";
import { Ticketing } from "../components/homeComponents/ticketing/ticketingView";
import HeaderComp from "../components/header";
import { useToast } from "../hooks/toast";
import { ProfileData } from "./MeuPerfil";
import { useAuth } from "../hooks/auth";
import AccountsService from "../services/accounts";
import { Core } from "../components/homeComponents/core/coreView";
import { SettingsView } from "../components/homeComponents/settings/settingsView";

const Home: React.FC = () => {
  const [profile, setProfile] = React.useState<ProfileData>({} as ProfileData);
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const services = { AccountsService: new AccountsService() };

  const group = localStorage.getItem("@UserGroup:group");
  const groupParse = JSON.parse(group);

  React.useEffect(() => {
    const getProfile = async () => {
      setProfile(null);
      try {
        const response = await services.AccountsService.getProfile();
        setProfile(response.data);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: "error",
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Erro no Servidor"
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : "Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador"
          }`,
        });
        if (err) {
          signOut();
        }
      }
    };
    getProfile();
  }, []);

  return (
    <>
      <HeaderComp filtroNaparece={true} homeNaparece={true} />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          margin: 0,
        }}
      >
        <Container
          sx={{
            display: "flex",
            paddingBottom: "24px",
            paddingTop: "24px",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ListItem>
            <ItemText
              primary="Administração do Site"
              hideSecondary
              primarySx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: "28px",
                textAlign: "center",
              }}
            />
          </ListItem>
          {/* <Accounts aside={false} /> */}
          {groupParse?.map((dados: any) =>
            dados?.name === "Marketing" ? (
              <Core aside={false} />
            ) : dados?.name === "Chargeback" ? (
              <Ticketing aside={false} />
            ) : (
              <>
                <Core aside={false} />
                <Ticketing aside={false} />
                <SettingsView aside={false} />
              </>
            )
          )}
        </Container>
      </Box>
    </>
  );
};

export default Home;
