/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
/* eslint-disable import/order */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import * as Yup from "yup";

import {
  Box,
  Container,
  List,
  ListItemButton,
  ListSubheader,
  Stack,
} from "@mui/material";

import { ItemText } from "../itemText";
import theme from "../../theme";
import { Ticketing } from "../homeComponents/ticketing/ticketingView";
import { Accounts } from "../homeComponents/accounts/accountsView";
import { useLoader } from "../../hooks/loader";
import { useAuth } from "../../hooks/auth";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  GridContainer,
  Header,
  Aside,
  Main,
  MainOverview,
  Footer,
  AsideMenu,
} from "./styles";
import DashBoardLoader from "../DashBoardLoader";
import { FiltroRecargas } from "./filtroRecarga";
import { LoadingButton } from "@mui/lab";
import { useCallback } from "react";

interface TiposdeFiltro {
  tipofiltro?: string;
}

interface ChildrenProps extends TiposdeFiltro {
  children?: any;
  datainicial?: any;
  datafinal?: any;
  tipoStatus?: any;
  tipoRecKonduto?: any;
  tipopagamento?: any;
  tipoestocduplicada?: any;
  tipocartao?: any;
  porOrigem?: any;
  porLogs?: any;
  porGateway?: any;
  dadosFiltros?: any;
}

interface DadosFiltros {
  value: string;
  label: string;
}

interface Filtros {
  dadosPorpedidoIni: DadosFiltros | null;
  dadosPorpedidoFnl: DadosFiltros | null;
  dadosPorStatus: DadosFiltros | null;
  dadosPorRecKonduto: DadosFiltros | null;
  dadosPorTipoPagamento: DadosFiltros | null;
  dadosPorTipoEstocagem: any; // ajuste conforme necessário
  dadosPorTipoCartao: DadosFiltros | null;
  dadosPorOrigem: DadosFiltros | null;
  dadosPorGateway: DadosFiltros | null;
  dadosPorLogs: DadosFiltros | null;
}

const Filter: React.FC<ChildrenProps> = ({
  children,
  datainicial,
  datafinal,
  tipofiltro,
  tipoStatus,
  dadosFiltros,
  tipoRecKonduto,
  tipopagamento,
  tipoestocduplicada,
  tipocartao,
  porOrigem,
  porGateway,
  porLogs,
}) => {
  const [menuActive, setMenuActive] = React.useState(false);
  const { user, signOut } = useAuth();
  const { push } = useHistory();
  const { isActive } = useLoader();

  const [dadosdosFiltros, setDadosdosFiltros] = React.useState({
    dadosPorpedidoIni: null,
    dadosPorpedidoFnl: null,
    dadosPorStatus: null,
    dadosPorRecKonduto: null,
    dadosPorTipoPagamento: null,
    dadosPorTipoEstocagem: null,
    dadosPorTipoCartao: null,
    dadosPorOrigem: null,
    dadosPorGateway: null,
    dadosPorLogs: null,
  });

  const receberDados = (dados: any) => {
    const { fOrigem } = dados || {};
    setDadosdosFiltros((prevState) => {
      const updatedFilters = { ...prevState };

      if (fOrigem === "status") {
        updatedFilters.dadosPorStatus = dados;
      } else if (
        fOrigem === "konduto_recommendation" ||
        fOrigem === "konduto_recommendation_isnull"
      ) {
        updatedFilters.dadosPorRecKonduto = dados;
      } else if (fOrigem === "payment_type") {
        updatedFilters.dadosPorTipoPagamento = dados;
      } else if (fOrigem === "estocagem") {
        updatedFilters.dadosPorTipoEstocagem = dados;
      } else if (fOrigem === "origin") {
        updatedFilters.dadosPorOrigem = dados;
      } else if (fOrigem === "gateway") {
        updatedFilters.dadosPorGateway = dados;
      } else if (fOrigem === "card_type") {
        updatedFilters.dadosPorTipoCartao = dados;
      } else if (fOrigem === "needs_to_be_verified") {
        updatedFilters.dadosPorLogs = dados;
      } else if (fOrigem === "date_after") {
        updatedFilters.dadosPorpedidoFnl = dados;
      } else if (fOrigem === "date_before") {
        updatedFilters.dadosPorpedidoIni = dados;
      }

      return updatedFilters;
    });
  };

  const handleFilter = useCallback(async () => {
    dadosFiltros(dadosdosFiltros);
  }, [dadosdosFiltros]);

  const userName = () => {
    return user.full_name ? user.full_name.split(" ")[0] : "";
  };

  const haldleSetMenu = React.useCallback(() => {
    setMenuActive(!menuActive);
  }, [menuActive]);

  return (
    <>
      <GridContainer active={menuActive}>
        <Aside>
          <div className="topo">
            {menuActive && (
              <button
                type="button"
                className="aside_close-icon"
                onClick={haldleSetMenu}
              >
                <strong> &#9776;</strong>
              </button>
            )}
          </div>
          <AsideMenu>
            {tipofiltro === "/recargas" && (
              <FiltroRecargas
                aside={true}
                datainicial={receberDados}
                datafinal={receberDados}
                tipoStatus={receberDados}
                tipoRecKonduto={receberDados}
                tipopagamento={receberDados}
                tipoestocduplicada={receberDados}
                tipocartao={receberDados}
                porOrigem={receberDados}
                porLogs={receberDados}
                porGateway={receberDados}
              />
            )}
          </AsideMenu>
          <ListItemButton
            component={LoadingButton}
            type="submit"
            style={{
              display: "flex",
              width: "98%",
              backgroundColor: "var(--purple)",
              color: "white",
              textAlign: "center",
              borderRadius: "16px",
              margin: "1%",
            }}
            onClick={handleFilter}
          >
            <ItemText primary="APLICAR FILTRO" hideSecondary />
          </ListItemButton>
        </Aside>
        <Main>
          <MainOverview>{children}</MainOverview>
        </Main>
        <Footer />
      </GridContainer>
    </>
  );
};

export default Filter;
