import styled from "styled-components";

interface Checkboxdiv {
  checked: boolean;
}

export const CheckboxContainer = styled.span<Checkboxdiv>`
  width: 100%;
  height: 100px;
  padding-left: 10px;
  margin: 0px 4px;
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;

  border: 1px solid
    ${(Props) => (Props.checked ? "#dc582a" : "var(--gray-line)")};

  display: flex;
  align-items: center;
  flex: 1;
`;

export const Text = styled.label`
  text-align: left;
  font-weight: normal;
  padding-top: 5px;
  color: #4d4a48;
  padding-left: 5px;
`;
export const StyledCheckbox2 = styled.label<Checkboxdiv>`
  width: 30px;
  height: 30px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid
    ${(Props) => (Props.checked ? "#dc582a" : "var(--gray-line)")};
  padding-lef: 10px;
  position: relative;
`;

export const StyledCheckbox = styled.label<Checkboxdiv>`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${(Props) => (Props.checked ? "#dc582a" : "white")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-lef: 20px;
  position: absolute;
  left: 13.8%;
`;

export const HiddenCheckbox = styled.input`
  overflow: hidden;
  white-space: nowrap;
  width: 30px;
  height: 30px;
  padding: 0;
  opacity: 0%;
`;
