import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;
  border-radius: 20px 20px;
  max-width: 100%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  & > div {
    padding: 0;
    margin: 0;
    max-height: inherit !important;
  }

  .rdt_TableCol {
    background-color: var(--aside-background);
    display: flex;
    max-width: 100%;
    padding: 10px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    overflow-x: auto;
    @media (max-width: 1400px) {
      max-width: 60%;
    }
    @media (max-width: 600px) {
      display: flex;
      font-size: 0.8rem;
      padding: 5px;
    }
    @media (max-width: 500px) {
      min-width: 40px;
    }

    div {
      color: var(--purple) !important;
      font-weight: bold;
    }
  }

  .hemgRv {
    border-radius: 0 0 20px 20px;
  }

  .rdt_TableBody {
  }

  .rdt_TableRow {
    height: 100px;
    @media (max-width: 1200px) {
      height: 150px;
    }
    @media (max-width: 1000px) {
      height: 180px;
    }
    @media (max-width: 600px) {
      font-size: 10px;
      height: 160px;
    }

    @media (max-width: 500px) {
      min-width: 40px;
    }
    font-size: 14px;
    width: 100%;
  }

  .rdt_TableCell {
    max-width: 100%;
    & > div {
      white-space: inherit;
      @media (max-width: 600px) {
        width: 50px;
      }
      @media (max-width: 500px) {
        min-width: 20px;
      }
    }
  }

  .emptyText {
    padding: 16px 0;
  }
`;
