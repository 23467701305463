/* eslint-disable react/require-default-props */
import React, { createContext, useCallback, useState, useContext } from "react";

interface LoaderContextData {
  isActive: boolean;
  changeLoader(active: boolean): void;
}

interface ChildrenProps {
  children?: any;
}

const LoaderContext = createContext<LoaderContextData>({} as LoaderContextData);

const LoaderProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const changeLoader = useCallback(async (active: any) => {
    setIsActive(active);
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        isActive,
        changeLoader,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

function useLoader(): LoaderContextData {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error("useLoader só pode ser usado com um LoaderProvider");
  }

  return context;
}

export { LoaderProvider, useLoader };
