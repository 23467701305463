/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import { useHistory, useParams } from "react-router-dom";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { ItemText } from "../../../../components/itemText";
import theme from "../../../../theme";
import HeaderComp from "../../../../components/header";
import TicketingService from "../../../../services/ticketing";
import Loader from "../../../../components/Loader";
import { useToast } from "../../../../hooks/toast";
import TableLayout from "./Components/TableLayout";

const CardId: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const { push } = useHistory();
  const services = { ticketingService: new TicketingService() };
  const [dadosRecarga, setDadosRecarga] = useState(null);
  const { addToast } = useToast();
  const [alertando, setAlertando] = React.useState<boolean>(false);

  const pageSize = useMediaQuery("(max-width:800px)");

  const getCardID = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.ticketingService.getRecargaId(id);
      setDadosRecarga(response?.data);
    } catch (err: any) {
      const { ...error } = err;

      console.log(error);
      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.ticketingService.postRecargaUpdate(id);

      addToast({
        type: "success",
        title: `Requisição enviada com Sucesso.`,
        description: `Status Atual: ${response?.data?.detail}`,
      });
      getCardID();
    } catch (err: any) {
      const { ...error } = err;

      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const direcionaRecarga = () => {
    push("/recargas");
  };

  useEffect(() => {
    getCardID();
  }, []);

  useEffect(() => {
    if (dadosRecarga?.needs_to_be_verified === true) {
      setAlertando(true);
    }
  }, [dadosRecarga, alertando]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderComp filtroNaparece={true} />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "50px",
              justifyContent: alertando ? "center" : "center",
            }}
          >
            <ListItem
              component={LoadingButton}
              onClick={direcionaRecarga}
              sx={{
                borderRadius: "10px",
                width: pageSize ? "100px" : "150px",
                border: `1px solid ${theme.palette.secondary.main}`,
                marginRight: "10px",
                marginTop: "12px",
              }}
              className="buttons"
            >
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.main, marginRight: "5px" }}
              />
              <ItemText
                primary="VOLTAR"
                hideSecondary
                primarySx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  fontSize: pageSize ? "10px" : "14px",
                  borderRadius: "10px",
                }}
              />
            </ListItem>
            {alertando && (
              <Stack
                sx={{
                  marginLeft: "5%",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p>!Pedido com Alerta de Logs</p>
                <CrisisAlertIcon
                  sx={{
                    height: "50px",
                    width: "50px",
                    color: theme.palette.primary.main,
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                  className="updatedata"
                />
              </Stack>
            )}
          </Stack>
          <Box
            sx={{
              display: "flex",
              margin: 0,
            }}
          >
            <Container
              sx={{
                display: "flex",
                paddingBottom: "24px",
                paddingTop: "12px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  margin: 0,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflowX: "auto",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <Stack>
                        <ListSubheader
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="Dados da Recarga"
                              hideSecondary
                              primarySx={{
                                color: "white",
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "left-start",
                              }}
                            />
                          </Box>
                        </ListSubheader>
                      </Stack>
                      <TableLayout
                        label="Codigo Externo:"
                        value={dadosRecarga?.external_code}
                      />
                      <TableLayout
                        label="Origem:"
                        value={dadosRecarga?.origin}
                      />
                      <TableLayout
                        label="Status:"
                        value={dadosRecarga?.status_display}
                        isProcessing={[
                          "processing",
                          "waiting_payment_confirmation",
                          "payment_not_made",
                        ].includes(dadosRecarga?.status)}
                        handleUpdate={handleUpdate}
                      />
                      <TableLayout
                        label="User:"
                        value={dadosRecarga?.user_email}
                      />
                      <TableLayout
                        label="Cartão Transfacil:"
                        value={`${dadosRecarga?.card} / ${dadosRecarga?.card_data?.card_number}`}
                      />
                      <TableLayout
                        label="Forma de Pagamento:"
                        value={
                          dadosRecarga?.form_of_payment_data
                            ?.payment_type_display === "Boleto" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <p>{`${dadosRecarga?.form_of_payment} / ${dadosRecarga?.form_of_payment_data?.payment_type_display}`}</p>
                              <a
                                href={dadosRecarga?.tacom_boleto}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color: theme.palette.primary.main,
                                  marginLeft: "10px",
                                }}
                              >
                                <DocumentScannerIcon
                                  sx={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </a>
                            </div>
                          ) : (
                            `${dadosRecarga?.form_of_payment} / ${dadosRecarga?.form_of_payment_data?.payment_type_display}`
                          )
                        }
                      />
                      <TableLayout
                        label="Recomendação Konduto:"
                        value={dadosRecarga?.konduto_recommendation}
                      />
                      <TableLayout
                        label="Cartão Transfacil:"
                        value={`${dadosRecarga?.card} / ${dadosRecarga?.card_data?.card_number}`}
                      />
                      <TableLayout
                        label="ID MUNDIPAGG:"
                        value={dadosRecarga?.mundipagg_order_id}
                      />
                      <TableLayout
                        label="Codigo Stone:"
                        value={`${dadosRecarga?.code_stone}`}
                      />
                      <TableLayout
                        label="ID TACOM:"
                        value={dadosRecarga?.tacom_order_id}
                      />
                      <TableLayout
                        label="Valor:"
                        value={dadosRecarga?.amount}
                      />
                      <TableLayout
                        label="Data do Pedido:"
                        value={dadosRecarga?.created_at}
                      />
                      <TableLayout
                        label="Ultima Atualização:"
                        value={dadosRecarga?.updated_at}
                      />
                    </Box>
                  </List>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default CardId;
