import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;

  img {
    width: 252px;
    height: 55px;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    div {
      height: 50px;
      background: var(--white);
      border-radius: 10px;
      margin-bottom: 8px;

      display: flex;
      flex-direction: row-reverse;
    }

    .email > div {
      margin-top: 26px;
      margin-bottom: 6px;
    }

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 26px;

      color: #4d4a48;
    }
    span {
      color: #d54f5b;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    .entrar {
      height: 50px;
      margin-top: 10px;

      background: var(--orange);
      border-radius: 10px;
      transition: all 0.2s;

      &:hover {
        background: #dc5129;
      }
    }
    a {
      color: #4d4a48;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }

    .forgpassword {
      height: 50px;
      margin-top: 10px;

      background: var(--purple);
      border-radius: 10px;
      transition: all 0.2s;

      &:hover {
        background: #dc5129;
      }
    }
    a {
      color: #4d4a48;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Forgot = styled.div`
  button {
    height: 30px;
    margin-top: -20px;
    color: var(--title-higlight);
    background: transparent;
  }

  .buttoncancelar {
    background: white;
    border: 1px solid var(--gray-line);
  }
`;

export const Register = styled.div`
  p {
    margin-top: 2rem;
  }
  strong {
    color: #d54f5b;
  }
`;

export const Company = styled.div`
  p {
    margin-top: 2rem;
  }
  span {
    color: #d54f5b;
  }
  button {
    background: transparent;
    color: #d54f5b;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 650px;
  padding: 0;

  .container {
    text-align: center;
    width: 90%;
    height: 470px;
    margin-left: 25px;

    h3 {
      margin-bottom: 20px;
    }

    .information {
      margin-bottom: 30px;
      text-align: justify;
      margin-left: 30px;
      width: 90%;
    }
    .acessar {
      width: 90%;
      background-color: var(--orange);
      margin-bottom: 30px;
      @media (max-width: 420px) {
        margin-left: 30px;
        width: 90%;
      }
    }
  }
`;

export const Dialogo = styled.div`
  position: relative;
  left: 25%;
  top: 20%;
  display: flex;
  background: white;
  justify-content: center;
  width: 50%;
  border-radius: 25px;
  @media (max-width: 900px) {
    left: 10%;
    width: 80%;
  }
  .container {
    text-align: center;
    width: 90%;
    height: 550px;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 100%;
    }
    h1 {
      text-align: left;
      font-size: 1.7rem;
      color: var(--orange);
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .password {
      text-align: left;
      margin-top: 4rem;
    }
    .text {
      padding: 40px;
      width: 100%;
      @media (max-width: 350px) {
        padding: 40px 10px 10px 10px;
      }
    }
    p {
      font-size: 1.2rem;
      color: var(--title-higlight);
      margin-bottom: 2.5rem;
      text-align: left;
    }
    .email {
      display: block;
    }
    .buttons {
      display: flex;
    }
    .enviar {
      width: 250px;
      margin-top: 2rem;
      margin-right: 3px;
      float: right;
      @media (max-width: 740px) {
        width: 100%;
        padding: 0px;
      }
    }
  }
  .containersent {
    text-align: center;
    width: 90%;
    height: 400px;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 100%;
    }
    h1 {
      text-align: left;
      font-size: 1.7rem;
      color: var(--orange);
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .password {
      text-align: left;
      margin-top: 2.5rem;
    }
    .text {
      padding: 40px;
      width: 100%;
      @media (max-width: 350px) {
        padding: 40px 10px 10px 10px;
      }
    }
    p {
      font-size: 1.2rem;
      color: var(--title-higlight);
      margin-bottom: 1.5rem;
      text-align: left;
    }
    .email {
      display: block;
    }
    .buttons {
      display: flex;
    }
    .enviar {
      width: 250px;
      margin-top: 2rem;
      margin-right: 3px;
      float: right;
      @media (max-width: 740px) {
        width: 100%;
        padding: 0px;
      }
    }

    .checkboxspan {
      height: 80px;
    }
  }
  .radiobox {
    height: 90px;
    flex-direction: row;
  }
  .checkcontainer {
    text-align: center;
    width: 90%;
    height: 550px;
    flex-direction: row;
    @media (max-width: 420px) {
      margin-left: 0px;
      width: 100%;
    }
    span {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h1 {
      text-align: left;
      font-size: 1.7rem;
      color: var(--orange);
      margin-top: 2.5rem;
      margin-bottom: 2rem;
    }
    .password {
      text-align: left;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
    .text {
      padding: 40px;
      width: 100%;
      @media (max-width: 350px) {
        padding: 40px 10px 10px 10px;
      }
    }
    p {
      font-size: 1rem;
      color: var(--title-higlight);
      margin-bottom: 1rem;
      text-align: left;
    }
    .checkbox {
      display: block;
    }
    .buttons {
      display: flex;
    }
    .enviar {
      margin-top: 0.5rem;
      margin-right: 3px;
      float: right;
      @media (max-width: 740px) {
        width: 100%;
        padding: 0px;
      }
    }
    .cancelar {
      margin-top: 0.5rem;
      margin-right: 3px;
      float: right;
      color: var(--title-higlight);
      font-weight: bolder;
      border: 1px solid var(--gray-line);
      @media (max-width: 740px) {
        width: 100%;
        padding: 0px;
      }
    }
    .sentfinal {
      margin-top: 0.5rem;
      flex-direction: row;
      margin-right: 3px;
      float: right;
      @media (max-width: 740px) {
        width: 100%;
        padding: 0px;
      }
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  left: 0%;
  display: none;
`;

export const Close = styled.div`
  color: var(--title-higlight);
  font-size: 30px;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-top: 30px;
  float: right;
  cursor: pointer;
`;
export const Checkimg = styled.label`
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background: #2bb673;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    filter: invert(75%) sepia(11%) saturate(6042%) hue- rotate(30deg)
      brightness(105%) contrast(68%);
  }
`;
