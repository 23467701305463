/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiMail, FiPhoneCall, FiKey, FiUser, FiLogOut } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import * as Yup from "yup";

import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ProfileData } from "../../../../../MeuPerfil";
import { useToast } from "../../../../../../hooks/toast";
import getValidationErrors from "../../../../../../utils/getValidationErrors";

import Input from "../../../../../../components/Input";

import AccountsService from "../../../../../../services/accounts";

import { ContainerPrincipal, FileDiv } from "./styles";
import CoreService from "../../../../../../services/core";
import { ItemText } from "../../../../../../components/itemText";
import theme from "../../../../../../theme";
import FileInputSpecial from "../FileInputSpecial";
import Select from "../../../../../../components/Select";
import { CheckBox } from "../../../../../../components/Checkbox";
import ButtonStyled from "../../../../../../components/Button";
import Loader from "../../../../../../components/Loader";

interface BannerInformationProps {
  dados: any;
  label?: string;
  value?: any;
}

const BannerLayoutId: React.FC = () => {
  const formRef = useRef<FormHandles>();
  const pageSize = useMediaQuery("(max-width:800px)");
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dadosBanner, setDadosBanner] = useState(null);
  const [isChecked, setIsChecked] = useState(dadosBanner?.is_active || false);
  const services = { coreServices: new CoreService() };
  const { addToast } = useToast();

  const TipoOptions = [
    { value: "header", label: "Header" },
    { value: "footer", label: "Footer" },
    { value: "middle", label: "Middle" },
    { value: "array", label: "Lista" },
  ];

  const CheckOptions = [
    { value: true, label: "Ativo" },
    { value: false, label: "Inativo" },
  ];

  const [selectedTipo, setSelectedTipo] = useState<any>();
  const [selectedCheck, setSelectedCheck] = useState<any>();

  const getBannerMobID = useCallback(async () => {
    try {
      const response = await services.coreServices.getBannerWebId(id);
      setIsChecked(response?.data?.is_active);
      setDadosBanner(response?.data);
      const tipoF = TipoOptions.filter(
        (filtros: any) => filtros?.value === response?.data?.banner_type
      );
      const tipoC = CheckOptions.filter(
        (filtros: any) => filtros?.value === response?.data?.is_active
      );
      setSelectedCheck(tipoC);
      setSelectedTipo(tipoF);
    } catch (err: any) {
      const { ...error } = err;
      setLoading(false);

      console.log(error);
      addToast({
        type: "error",
        title: `${error.response.data.detail}`,
        description: `${error.response.data.detail}`,
      });
    } finally {
      setLoading(false);
    }
  }, [loading]);

  const handleTipoChange = useCallback(
    (selectedOption) => {
      setSelectedTipo(selectedOption);
    },
    [selectedTipo]
  );

  const handleCheckChange = useCallback(
    (selectedOption) => {
      setSelectedCheck(selectedOption);
    },
    [selectedCheck]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const formData = new FormData();
        // changeLoader(true);

        const result: any = {};

        if (data?.tipo !== undefined) {
          result.tipo = data.tipo;
        }

        if (data?.backgroundCor !== undefined) {
          result.backgroundCor = data.backgroundCor;
        }

        if (data?.backgroundbannerfile !== undefined) {
          result.backgroundImage = data.backgroundbannerfile;
        }

        if (data?.imagePromotionfile !== undefined) {
          result.imagePromotion = data.imagePromotionfile;
        }

        if (data?.cta_color !== undefined) {
          result.cta_color = data.cta_color;
        }

        if (data?.cta_text !== undefined) {
          result.cta_text = data.cta_text;
        }

        if (data?.is_active !== undefined) {
          result.is_active = data.is_active;
        }

        if (data?.media_link !== undefined) {
          result.media_link = data.media_link;
        }

        if (data?.title !== undefined) {
          result.title = data.title;
        }

        if (data?.subtitle !== undefined) {
          result.subtitle = data.subtitle;
        }
        for (const name in result) {
          if (Object.prototype.hasOwnProperty.call(result, name)) {
            formData.append(name, result[name]);
          }
        }

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await services.coreServices.patchBannerWeb(
          id,
          formData
        );

        addToast({
          type: "success",
          title: "Banner atualizado.",
          description: "Dados do Banner atualizados com sucesso.",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (err: any) {
        const { ...error } = err;

        if (error?.response?.data?.detail) {
          addToast({
            type: "error",
            title: "Erro ao enviar formulario.",
            description: error?.response?.data?.detail,
          });
        }

        addToast({
          type: "error",
          title: "Erro ao enviar formulario.",
          description:
            "Verifique se nenhum campo esta em branco, caso o erro persista favor entrar em contato com o adminsitrador.",
        });

        console.log({ error });

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, selectedTipo, selectedCheck]
  );

  useEffect(() => {
    getBannerMobID();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ContainerPrincipal>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={dadosBanner}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Status"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                  name="is_active"
                  defaultValue={CheckOptions?.filter(
                    (data?: any) => data?.value === dadosBanner?.is_active
                  )}
                  className="select"
                  isSearchable={false}
                  value={selectedCheck}
                  onChange={handleCheckChange}
                  options={CheckOptions}
                />
                {/* <CheckBox
              name="is_active"
              options={[
                {
                  id: "is_active",
                  value: `${selectedCheck}`,
                  defaultValueCheck: dadosBanner?.is_active,
                },
              ]}
              onChange={handleCheckBoxChange}
            />
            <p>Checkbox está {selectedCheck ? "marcado" : "desmarcado"}</p> */}
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Tipo"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                  name="tipo"
                  defaultValue={TipoOptions?.filter(
                    (data?: any) => data?.value === dadosBanner?.banner_type
                  )}
                  className="select"
                  isSearchable={false}
                  value={selectedTipo}
                  onChange={handleTipoChange}
                  options={TipoOptions}
                />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Cor de Fundo"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input id="backgroundCor" name="backgroundCor" />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Imagem de Fundo"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <a
                  href={dadosBanner?.backgroundImage}
                  target="_blank"
                  rel="noreferrer"
                  className="urlLink"
                >
                  Imagem atual
                </a>
                <FileDiv>
                  <FileInputSpecial
                    name="backgroundbannerfile"
                    title="Selecione um Arquivo"
                  />
                </FileDiv>
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Cor do Botão"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input id="cta_color" name="cta_color" />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Texto do Botão"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input id="cta_text" name="cta_text" />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Imagem de Divulgação"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <a
                  href={dadosBanner?.imagePromotion}
                  target="_blank"
                  rel="noreferrer"
                  className="urlLink"
                >
                  Imagem atual
                </a>
                <FileDiv>
                  <FileInputSpecial
                    name="imagePromotionfile"
                    title="Selecione um Arquivo"
                  />
                </FileDiv>
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Link"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <p>
                  Link atual: {""}
                  <a
                    href={dadosBanner?.media_link}
                    target="_blank"
                    rel="noreferrer"
                    className="urlLink"
                  >
                    {dadosBanner?.media_link}
                  </a>
                </p>
                <Input id="media_link" name="media_link" />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Titulo"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input
                  id="title"
                  name="title"
                  multiline
                  className="Multiline"
                />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Subtitulo"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Input
                  id="subtitle"
                  name="subtitle"
                  multiline
                  className="Multiline"
                />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Criado em"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ItemText
                  primary={dadosBanner?.created_at}
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItem>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <ListItemButton
                component={LoadingButton}
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "100px" : "200px",
                }}
              >
                <ItemText
                  primary="Atualizado em"
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItemButton>
              <ListItem
                sx={{
                  borderRadius: "10px",
                  width: pageSize ? "auto" : "400px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ItemText
                  primary={dadosBanner?.updated_at}
                  hideSecondary
                  primarySx={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    fontSize: pageSize ? "12px" : "16px",
                    borderRadius: "10px",
                  }}
                />
              </ListItem>
            </Stack>
            <ButtonStyled
              color="purple"
              padding="1"
              type="submit"
              className="searchButton"
              style={{
                marginLeft: "5px",
                marginTop: pageSize ? "0px" : "0px",
                fontWeight: "bold",
              }}
            >
              Atualizar
            </ButtonStyled>
          </Form>
        </ContainerPrincipal>
      )}
    </>
  );
};

export default BannerLayoutId;
