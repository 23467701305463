/* eslint-disable jsx-a11y/control-has-associated-label */
import { Stack, ListItemButton, ListItem, useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReplayIcon from "@mui/icons-material/Replay";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { ItemText } from "../../../../../../components/itemText";
import theme from "../../../../../../theme";

interface TableLayoutProps {
  label?: string;
  value?: any;
  link?: any;
  isProcessing?: any;
  handleUpdate?: any;
}

const TableLayout: React.FC<TableLayoutProps> = ({
  label,
  value,
  link,
  isProcessing,
  handleUpdate,
}) => {
  const pageSize = useMediaQuery("(max-width:800px)");
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <ListItemButton
        component={LoadingButton}
        sx={{
          borderRadius: "10px",
          width: pageSize ? "100px" : "200px",
        }}
      >
        <ItemText
          primary={label}
          hideSecondary
          primarySx={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
            fontSize: pageSize ? "12px" : "16px",
            borderRadius: "10px",
          }}
        />
      </ListItemButton>
      <ListItem
        sx={{
          borderRadius: "10px",
          width: pageSize ? "auto" : "400px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ItemText
          primary={value}
          hideSecondary
          primarySx={{
            fontSize: pageSize ? "14px" : "18px",
            fontWeight: "bold",
            borderRadius: "10px",
          }}
        />
        {isProcessing && (
          <ReplayIcon
            className="iconreload"
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: "10px",
              color: "white",
              padding: "5px",
              height: "35px",
              width: "45px",
              cursor: "pointer",
              marginTop: "-5px",
              marginLeft: "5px",
              marginRight: "15px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
            onClick={handleUpdate}
          />
        )}
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{
              color: theme.palette.primary.main,
              marginLeft: "10px",
            }}
          >
            <DocumentScannerIcon
              sx={{
                height: "30px",
                width: "30px",
              }}
            />
          </a>
        )}
      </ListItem>
    </Stack>
  );
};

export default TableLayout;
