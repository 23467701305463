import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "./styles/global";

import AppProvider from "./hooks";

import Routes from "./routes";

const App: React.FC = () => {
  if (window.location.href === process.env.REACT_APP_REDIRECT_URL) {
    window.location.href = "/";
  }

  return (
    <div>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
      </BrowserRouter>
      <GlobalStyle />
    </div>
  );
};

export default App;
