/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  Stack,
  useMediaQuery,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  ListItemButton,
} from "@mui/material";

import { formatCurrency } from "@brazilian-utils/brazilian-utils";

import { Form } from "@unform/web";
import { MdFilterList, MdSearch } from "react-icons/md";
import CachedIcon from "@mui/icons-material/Cached";
import { FormHandles } from "@unform/core";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ItemText } from "../../../components/itemText";
import theme from "../../../theme";
import NavBar from "../../../components/navBar";
import Filter from "../../../components/filtro";
import HeaderComp from "../../../components/header";
import SideBar from "../../../components/sidebar";
import { Container } from "./styles";
import { FilterSearchButton } from "../../../components/filterSearchButton";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ButtonStyled from "../../../components/Button";
import { ArrayFilters } from "../../../interfaces/filterSearch";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useToast } from "../../../hooks/toast";
import TicketingService from "../../../services/ticketing";
import {
  RechargesOptions,
  RechargesResponse,
} from "../../../interfaces/recharge";
import { CardsTable } from "./Components/CardsTable";
import { ProfileData } from "../../MeuPerfil";
import { useAuth } from "../../../hooks/auth";
import AccountsService from "../../../services/accounts";
import Loader from "../../../components/Loader";

interface DadosFiltros {
  value: string;
  label: string;
}

const Cards: React.FC = () => {
  const [profile, setProfile] = React.useState<ProfileData>({} as ProfileData);
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const servicesacc = { AccountsService: new AccountsService() };
  const services = { ticketingService: new TicketingService() };

  const pageSize = useMediaQuery("(max-width:1600px)");

  const [dadosPorpedidoIni, setDadosPorpedidoIni] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorpedidoFnl, setDadosPorpedidoFnl] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorStatus, setDadosPorStatus] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorRecKonduto, setDadosPorRecKonduto] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorTipoPagamento, setDadosPorTipoPagamento] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorTipoEstocagem, setDadosPorTipoEstocagem] = React.useState();
  const [dadosPorTipoCartao, setDadosPorTipoCartao] =
    React.useState<DadosFiltros | null>(null);
  const [dadosPorOrigem, setDadosPorOrigem] =
    React.useState<DadosFiltros | null>(null);

  const [dadosBusca, setDadosBusca] = React.useState("");

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{}],
  });
  const [pordatapedido, setPordatapedido] = useState();
  const [allRecharges, setAllRecharges] = useState<RechargesResponse>(
    {} as RechargesResponse
  );
  const recargaOptions = [
    { value: "external_code", label: "Codigo Externo" },
    { value: "email", label: "Email" },
    { value: "cpf", label: "CPF" },
    { value: "mundipagg_order_id", label: "ID Mundipagg" },
    { value: "tacom_order_id", label: "ID Tacom" },
    { value: "code_stone", label: "Codigo Stone" },
    { value: "card", label: "Cartão Transfácil" },
  ];

  const [selectedRecarga, setSelectedRecarga] = useState(recargaOptions[0]); // Inicializando com a primeira opção

  // 2. Criar uma função para atualizar esse estado
  const handleRecargaChange = useCallback((selectedOption) => {
    setSelectedRecarga(selectedOption);
  }, []);

  const formRef = React.useRef<FormHandles>(null);

  // Defina a função de callback para receber os dados do componente filho
  const receberDadosPorpedidoIni = (dados: any) => {
    setDadosPorpedidoIni(dados);
  };
  const receberDadosPorpedidoFnl = (dados: any) => {
    setDadosPorpedidoFnl(dados);
  };
  const receberDadosStatus = (dados: any) => {
    setDadosPorStatus(dados);
  };

  const receberDadosRecKonduto = (dados: any) => {
    setDadosPorRecKonduto(dados);
  };

  const receberDadosTipoPagamento = (dados: any) => {
    setDadosPorTipoPagamento(dados);
  };

  const receberDadosTipoEstocagem = (dados: any) => {
    setDadosPorTipoEstocagem(dados);
  };

  const receberDadosTipoCartao = (dados: any) => {
    setDadosPorTipoCartao(dados);
  };

  const receberDadosPorOrigem = (dados: any) => {
    setDadosPorOrigem(dados);
  };

  const getCardsList = useCallback(async () => {
    setLoading(true);
    if (filters.filters.length === 1) {
      localStorage.removeItem("RecargaStatus");
      localStorage.removeItem("data");
      localStorage.removeItem("konduto");
      localStorage.removeItem("tipopagamento");
      localStorage.removeItem("duplicada");
      localStorage.removeItem("tipocartao");
      localStorage.removeItem("origem");
    }
    const response = await services.ticketingService.getRecarga(filters);
    setAllRecharges(response?.data);
    setTotalRows(response.data.count);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [filters, dadosBusca]);

  const handleClearFilters = useCallback(() => {
    formRef.current?.reset();
    setFilters({
      filters: [{ page }],
    });
    window.location.reload();
  }, []);

  const handleRemoveFilter = useCallback((name: any) => {
    if (name === "status") {
      localStorage.removeItem("RecargaStatus");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("status")
          ),
        ],
      }));
    } else if (name === "konduto") {
      localStorage.removeItem("konduto");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) =>
              !filter.hasOwnProperty(
                "konduto_recommendation" || "konduto_recommendation_isnull"
              )
          ),
        ],
      }));
    } else if (name === "tipopagamento") {
      localStorage.removeItem("tipopagamento");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("payment_type")
          ),
        ],
      }));
    } else if (name === "data") {
      localStorage.removeItem("data");
      setFilters({
        filters: [
          ...filters.filters.filter(
            (filter) => !filter.hasOwnProperty("date_before" && "date_after")
          ),
        ],
      });
    } else if (name === "tipocartao") {
      localStorage.removeItem("tipocartao");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("card_type")
          ),
        ],
      }));
    } else if (name === "origem") {
      localStorage.removeItem("origem");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("origin")
          ),
        ],
      }));
    }
    setFilters((prevFilters) => ({
      filters: [
        ...prevFilters.filters.filter(
          (filter) => !filter.hasOwnProperty("page")
        ),
      ],
    }));
  }, []);

  const handleNumberOrderFilter = useCallback(
    (data: any) => {
      const searchFor = data.recarga;
      const updatedFilters = filters.filters.filter(
        (filter) =>
          !(
            filter.external_code ||
            filter.email ||
            filter.mundipagg_order_id ||
            filter.tacom_order_id ||
            filter.card ||
            filter.external_code ||
            filter.email ||
            filter.cpf ||
            filter.mundipagg_order_id ||
            filter.tacom_order_id ||
            filter.code_stone ||
            filter.tacom_order_id ||
            filter.card
          )
      );
      const newFilter =
        searchFor === "external_code"
          ? { external_code: data.search, page: 1 }
          : searchFor === "email"
          ? { email: data.search, page: 1 }
          : searchFor === "cpf"
          ? { cpf: data.search, page: 1 }
          : searchFor === "mundipagg_order_id"
          ? { mundipagg_order_id: data.search, page: 1 }
          : searchFor === "tacom_order_id"
          ? { tacom_order_id: data.search, page: 1 }
          : searchFor === "code_stone"
          ? { code_stone: data.search, page: 1 }
          : { card_number: data.search, page: 1 };

      const buscaF =
        searchFor === "external_code"
          ? `Codigo Externo: ${data.search}`
          : searchFor === "email"
          ? `Email: ${data.search}`
          : searchFor === "cpf"
          ? `Cpf: ${data.search}`
          : searchFor === "mundipagg_order_id"
          ? `ID Mundipag: ${data.search}`
          : searchFor === "tacom_order_id"
          ? `ID Tacom: ${data.search}`
          : searchFor === "code_stone"
          ? `Codigo Stone: ${data.search}`
          : `Cartão Transfácil: ${data.search}`;

      setDadosBusca(buscaF);
      updatedFilters.push(newFilter);

      // setDadosBusca(updatedFilters);

      // Atualiza o estado dos filtros
      setFilters({
        filters: updatedFilters,
      });
    },
    [filters, dadosBusca]
  );

  const handleChangePerPage = useCallback(
    async (newPage: number) => {
      setPage(newPage);
      setFilters({ filters: [...filters.filters, { page: newPage }] });
    },
    [filters]
  );

  const handleChangePerDate = useCallback(
    async (datainicial: any, datafinal: any) => {
      if (datainicial?.value === "todos" && datafinal?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("date_before" && "date_after")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        const dateini = { date_before: datafinal?.value };
        const datefin = { date_after: datainicial?.value };
        const updatedFilters = filters.filters.filter(
          (filter) => !(filter.date_before || filter.date_after)
        );

        updatedFilters.push(dateini, datefin);
        setFilters({
          filters: updatedFilters,
        });
      }
    },
    [filters.filters]
  );
  const handleChangePerStatus = useCallback(
    async (statusFilter: any) => {
      if (statusFilter?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("status")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        setFilters((prevFilters) => {
          const newFilters = prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("status")
          );
          return {
            filters: [
              ...newFilters,
              { status: statusFilter?.value },
              { page: 1 },
            ],
          };
        });
      }
    },
    [setFilters, setPage]
  );

  const handleChangePerRecKonduto = useCallback(
    async (reckonduto: any) => {
      if (reckonduto?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) =>
                !filter.hasOwnProperty(
                  "konduto_recommendation" || "konduto_recommendation_isnull"
                )
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        const updatedFilters = filters.filters.filter(
          (filter) =>
            !(
              filter.konduto_recommendation ||
              filter.konduto_recommendation_isnull
            )
        );
        const newFilter =
          reckonduto?.value === "True"
            ? { konduto_recommendation_isnull: reckonduto?.value, page: 1 }
            : { konduto_recommendation: reckonduto?.value, page: 1 };

        updatedFilters.push(newFilter);

        // Atualiza o estado dos filtros
        setFilters({
          filters: updatedFilters,
        });
      }
    },
    [filters.filters]
  );

  const handleChangePerTipoPagamento = useCallback(
    async (tipopagamento: any) => {
      if (tipopagamento?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("payment_type")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        setFilters({
          filters: [
            ...filters.filters,
            { payment_type: tipopagamento?.value },
            { page: 1 },
          ],
        });
      }
    },
    [filters.filters]
  );

  const handleChangeTipoEstocagem = useCallback(
    async (estocagem: string) => {
      if (estocagem === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("is_duplicate")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        setFilters({
          filters: [
            ...filters.filters,
            { is_duplicate: estocagem },
            { page: 1 },
          ],
        });
      }
    },
    [filters.filters]
  );

  const handleChangeTipoCartao = useCallback(
    async (cartao: any) => {
      if (cartao?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("card_type")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        setFilters({
          filters: [
            ...filters.filters,
            { card_type: cartao?.value },
            { page: 1 },
          ],
        });
      }
    },
    [filters.filters]
  );

  const handleChangePerOrigem = useCallback(
    async (origem: any) => {
      if (origem?.value === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty("origin")
            ),
          ],
        }));
        setPage(1);
      } else {
        setPage(1);
        setFilters({
          filters: [...filters.filters, { origin: origem?.value }, { page: 1 }],
        });
      }
    },
    [filters.filters]
  );

  useEffect(() => {
    getCardsList();
  }, [page, filters]);

  useEffect(() => {
    if (
      dadosPorpedidoIni?.value !== "null" &&
      dadosPorpedidoIni?.value !== null &&
      dadosPorpedidoIni?.value !== undefined
    ) {
      handleChangePerDate(dadosPorpedidoIni, dadosPorpedidoFnl);
    }
  }, [dadosPorpedidoIni, dadosPorpedidoFnl]);

  useEffect(() => {
    if (
      dadosPorStatus?.value !== "null" &&
      dadosPorStatus?.value !== null &&
      dadosPorStatus?.value !== undefined
    ) {
      handleChangePerStatus(dadosPorStatus);
    }
  }, [dadosPorStatus]);

  useEffect(() => {
    if (
      dadosPorRecKonduto?.value !== "null" &&
      dadosPorRecKonduto?.value !== null &&
      dadosPorRecKonduto?.value !== undefined
    ) {
      handleChangePerRecKonduto(dadosPorRecKonduto);
    }
  }, [dadosPorRecKonduto]);

  useEffect(() => {
    if (
      dadosPorTipoPagamento?.value !== "null" &&
      dadosPorTipoPagamento?.value !== null &&
      dadosPorTipoPagamento?.value !== undefined
    ) {
      handleChangePerTipoPagamento(dadosPorTipoPagamento);
    }
  }, [dadosPorTipoPagamento]);

  useEffect(() => {
    if (
      dadosPorTipoEstocagem !== "null" &&
      dadosPorTipoEstocagem !== null &&
      dadosPorTipoEstocagem !== undefined
    ) {
      handleChangeTipoEstocagem(dadosPorTipoEstocagem);
    }
  }, [dadosPorTipoEstocagem]);

  useEffect(() => {
    if (
      dadosPorTipoCartao?.value !== "null" &&
      dadosPorTipoCartao?.value !== null &&
      dadosPorTipoCartao?.value !== undefined
    ) {
      handleChangeTipoCartao(dadosPorTipoCartao);
    }
  }, [dadosPorTipoCartao]);

  useEffect(() => {
    if (
      dadosPorOrigem?.value !== "null" &&
      dadosPorOrigem?.value !== null &&
      dadosPorOrigem?.value !== undefined
    ) {
      handleChangePerOrigem(dadosPorOrigem);
    }
  }, [dadosPorOrigem]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("RecargaStatus");

      // Exemplo: salvar dados no localStorage antes de sair
      localStorage.setItem("lastVisit", new Date().toISOString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remover o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    const getProfile = async () => {
      setProfile(null);
      try {
        const response = await servicesacc.AccountsService.getProfile();
        setProfile(response.data);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: "error",
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Erro no Servidor"
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : "Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador"
          }`,
        });
        if (err) {
          signOut();
        }
      }
    };
    getProfile();
  }, []);

  useEffect(() => {}, [dadosBusca]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderComp
            filtroNaparece={true}
            datainicial={receberDadosPorpedidoIni}
            datafinal={receberDadosPorpedidoFnl}
            tipoStatus={receberDadosStatus}
            tipoRecKonduto={receberDadosRecKonduto}
            tipopagamento={receberDadosTipoPagamento}
            tipoestocduplicada={receberDadosTipoEstocagem}
            tipocartao={receberDadosTipoCartao}
            porOrigem={receberDadosPorOrigem}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
            }}
          >
            {/* {!pageSize && <NavBar />} */}
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Container>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Form
                      ref={formRef}
                      onSubmit={handleNumberOrderFilter}
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <Input
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Buscar recarga por..."
                        style={{
                          minWidth: pageSize ? "100px" : "300px",
                          borderColor: theme.palette.primary.main,
                        }}
                      >
                        <Select
                          name="recarga"
                          placeholder="Recarga"
                          defaultValue={{
                            value: "external_code",
                            label: "Codigo Externo",
                          }}
                          className="select"
                          isSearchable={false}
                          value={selectedRecarga}
                          onChange={handleRecargaChange}
                          options={recargaOptions}
                        />
                      </Input>

                      <ButtonStyled
                        color="orange"
                        padding="1"
                        type="submit"
                        className="searchButton"
                        style={{
                          marginLeft: "5px",
                          marginTop: pageSize ? "0px" : "0px",
                        }}
                      >
                        <MdSearch size={20} />
                      </ButtonStyled>
                    </Form>
                    <Box
                      sx={{
                        backgroundColor: "RGB(248, 248, 248)",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        overflowX: "auto",
                        display: "grid",
                      }}
                    >
                      <Stack
                        sx={{
                          zIndex: 0,
                        }}
                      >
                        <ListSubheader
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "10px",
                            backgroundColor: "RGB(248, 248, 248)",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="CARTÕES TRANSFÁCIL"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontSize: pageSize ? "30px" : "20px",
                                fontWeight: "bold",
                              }}
                            />
                          </Box>
                        </ListSubheader>
                      </Stack>
                      <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          textAlign: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "grid",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            marginRight: "20px",
                            border:
                              filters.filters.length > 1
                                ? `1px solid ${theme.palette.secondary.main}`
                                : "",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          {filters.filters.length > 1 && (
                            <ItemText
                              primary="Filtros Ativos:"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                          )}

                          {filters.filters.length <= 1 && (
                            <ItemText
                              primary="Nenhum Filtro Ativo"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                          )}
                          <Stack
                            sx={{
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                              marginRight: "20px",
                            }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                marginRight: "20px",
                                flexDirection: "column",
                              }}
                              direction={{ xs: "column", sm: "row" }}
                              spacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                              {filters?.filters?.map((filter: any) => {
                                if (filters.filters.length > 1) {
                                  if (
                                    filter?.external_code ||
                                    filter?.email ||
                                    filter?.cpf ||
                                    filter?.mundipagg_order_id ||
                                    filter?.tacom_order_id ||
                                    filter?.code_stone ||
                                    filter?.card
                                  ) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {dadosBusca}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("busca")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  }
                                }
                              })}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          <Stack
                            sx={{
                              display: "grid",
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <ItemText
                              primary={`Total de Pedidos: ${totalRows}`}
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                            <ListItemButton
                              onClick={handleClearFilters}
                              className="BotaoLimpar"
                              sx={{
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginTop: pageSize ? "0px" : "0px",
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "16px",
                                marginRight: "10px",
                                justifyContent: "center",
                              }}
                            >
                              Limpar Filtros
                            </ListItemButton>
                          </Stack>
                          <Stack
                            sx={{
                              marginLeft: "10px",
                              justifyContent: "center",
                            }}
                          >
                            <CachedIcon
                              sx={{
                                height: "50px",
                                width: "50px",
                                cursor: "pointer",
                                color: theme.palette.primary.main,
                              }}
                              className="updatedata"
                              onClick={getCardsList}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      {!loading && (
                        <div className="Tabela">
                          <CardsTable
                            data={allRecharges.results}
                            columns={[
                              {
                                name: <div>ID</div>,
                                selector: "id",
                                cell: ({ id }: any) => (
                                  <Link
                                    to={`/recargas/${id}`}
                                    className="custom-link"
                                  >
                                    <div className="specifitcell">{id}</div>
                                  </Link>
                                ),
                              },
                              {
                                name: <div>User</div>,
                                selector: "external_code",
                                cell: ({ external_code }: any) => (
                                  <div className="specifitcell">
                                    {external_code}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Apelido</div>,
                                selector: "user",
                                cell: ({ origin }: any) => (
                                  <div className="specifitcell">{origin}</div>
                                ),
                              },
                              {
                                name: <div>Número do Cartão</div>,
                                selector: "user",
                                cell: ({ user_email, id }: any) => (
                                  <div className="specifitcell">
                                    {user_email}
                                  </div>
                                ),
                              },
                              {
                                name: <div>TIPO</div>,
                                selector: "status",
                                cell: ({ status_display }: any) => (
                                  <div className="specifitcellS">
                                    {status_display}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Ultima Atualização</div>,
                                selector: "konduto_recommendation",
                                cell: ({ konduto_recommendation }: any) => (
                                  <div className="specifitcell">
                                    {konduto_recommendation}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Ativo</div>,
                                selector: "code_stone",
                                cell: ({ code_stone }: any) => (
                                  <div className="specifitcell">
                                    {code_stone}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Bloqueado</div>,
                                selector: "mundipagg_order_id",
                                cell: ({ mundipagg_order_id }: any) => (
                                  <div className="specifitcell">
                                    {mundipagg_order_id}
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </div>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 2,
                        }}
                      >
                        <Button
                          disabled={page === 1}
                          onClick={() => handleChangePerPage(page - 1)}
                        >
                          Anterior
                        </Button>
                        <Box
                          sx={{ marginX: 2 }}
                        >{`Página ${page} de ${Math.ceil(
                          totalRows / 39 // 10 itens por página, ajuste conforme necessário
                        )}`}</Box>
                        <Button
                          disabled={page * 39 >= totalRows} // 10 itens por página
                          onClick={() => handleChangePerPage(page + 1)}
                        >
                          Próxima
                        </Button>
                      </Box>
                    </Box>
                  </List>
                </Container>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Cards;
