import styled from "styled-components";

export const Container = styled.div`
  .iconView {
    color: var(--orange);
    margin: 2;
    :hover {
      color: var(--green);
    }
  }
  .iconCheck {
    color: var(--purple);
    margin: 2;
    cursor: pointer;
    :hover {
      color: var(--green);
    }
  }

  .iconNew {
    color: white;
    border-radius: 16px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .custom-link-new {
    margin-right: 5px;
    align-items: center;
    display: flex;
  }

  .addBanner {
    color: white;
    background-color: var(--orange);
    border-radius: 16px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    :hover {
      background-color: var(--purple);
    }
  }

  .iconDelete {
    color: var(--purple);
    margin: 2;
    cursor: pointer;
    :hover {
      color: var(--green);
    }
  }
  .buttonView {
    background-color: var(--purple);
    color: white;
  }
  .buttonView:hover {
    color: var(--purple);
    font-weight: bold;
  }

  h1 {
    color: var(--purple);
  }
  .custom-link:hover {
    font-weight: bold;
    font-size: 16px;
  }
  .custom-link {
    color: #3f1159;
    font-weight: bold;
    margin-left: 5%;
    margin-right: 5px;
  }

  .updatedata:hover {
    color: #3f1159;
  }

  .BotaoLimpar:hover {
    color: #3f1159;
  }

  .IconRemove:hover {
    color: #dc582a;
  }

  .button-transparent {
    background-color: var(--transparent) !important;
    color: var(--orange) !important;
    border: 1px solid var(--orange) !important;
  }
  .rdt_TableCol {
    min-width: 150px;
    text-align: center;
    justify-content: center;
    @media only screen and (max-width: 500px) {
      min-width: 50px;
    }
  }
  .rdt_TableHead {
    display: flex;
    width: auto;
  }

  .tableMaxWidth {
    width: 100%;
    overflow-x: auto;

    @media (max-width: 1200px) {
      overflow-x: auto;
    }
    .id_cell {
      width: 2px;
    }
  }

  .MuiTableCell-body {
    .id_cell {
      min-width: 2px;
    }
  }
  .MuiTableCell-root {
    max-width: 100%;
    padding: 10px;
    text-align: center;
    @media (max-width: 600px) {
      display: flex;
      font-size: 0.8rem;
      padding: 5px;
    }

    div {
      color: var(--purple) !important;
      font-weight: bold;
    }
  }
  .MuiTableCell-head {
    width: 100%;
  }

  .header {
    background-color: var(--aside-background);
    strong {
      color: var(--purple);
      .id_cell {
        width: 50px;
      }
    }
  }
`;

export const TabelaDiv = styled.div`
  width: 98%;
  margin: 1%;
  overflow-x: auto;
  justify-content: center;
  .specifitcell {
    width: 100%;
  }
  .rdt_TableCell {
    justify-content: center;
  }
  .rdt_TableHeadRow {
    justify-content: center;
  }
  div[data-column-id="1"] {
    min-width: 90px;
    max-width: 120px;
    @media only screen and (max-width: 500px) {
      max-width: 50px;
      min-width: 40px;
    }
  }
`;
