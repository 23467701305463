/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiMail, FiPhoneCall, FiKey, FiUser, FiLogOut } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import * as Yup from "yup";

import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProfileData } from "../../../MeuPerfil";
import { useToast } from "../../../../hooks/toast";
import getValidationErrors from "../../../../utils/getValidationErrors";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";

import AccountsService from "../../../../services/accounts";

import { ContainerPrincipal } from "./styles";
import CoreService from "../../../../services/core";
import { ItemText } from "../../../../components/itemText";
import theme from "../../../../theme";
import Loader from "../../../../components/Loader";
import HeaderComp from "../../../../components/header";
import VersionLayoutId from "./Components/BannerLayoutId";

const AppVersionNew: React.FC = () => {
  const { push } = useHistory();
  const pageSize = useMediaQuery("(max-width:800px)");
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);

  const direcionarBanner = () => {
    push("/appversion");
  };

  return (
    <ContainerPrincipal>
      <HeaderComp filtroNaparece={true} />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "50px",
          justifyContent: "center",
        }}
      >
        <ListItem
          component={LoadingButton}
          onClick={direcionarBanner}
          sx={{
            borderRadius: "10px",
            width: pageSize ? "100px" : "150px",
            border: `1px solid ${theme.palette.secondary.main}`,
            marginRight: "10px",
            marginTop: "12px",
          }}
          className="buttons"
        >
          <ArrowBackIcon
            sx={{ color: theme.palette.primary.main, marginRight: "5px" }}
          />
          <ItemText
            primary="VOLTAR"
            hideSecondary
            primarySx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              fontSize: pageSize ? "10px" : "14px",
              borderRadius: "10px",
            }}
          />
        </ListItem>
      </Stack>
      <Box
        sx={{
          display: "flex",
          margin: 0,
        }}
      >
        <Container
          sx={{
            display: "flex",
            paddingBottom: "24px",
            paddingTop: "12px",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              margin: 0,
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowX: "auto",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 4,
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                    color: theme.palette.secondary.main,
                  }}
                >
                  <Stack>
                    <ListSubheader
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <Box>
                        <ItemText
                          primary="Nova Versão do App"
                          hideSecondary
                          primarySx={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "left-start",
                          }}
                        />
                      </Box>
                    </ListSubheader>
                  </Stack>
                  <VersionLayoutId />
                </Box>
              </List>
            </Box>
          </Box>
        </Container>
      </Box>
    </ContainerPrincipal>
  );
};

export default AppVersionNew;
