/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  Stack,
  useMediaQuery,
  Button,
  ListItemButton,
  Tooltip,
} from "@mui/material";

import { formatCurrency } from "@brazilian-utils/brazilian-utils";

import { Form } from "@unform/web";
import { MdSearch } from "react-icons/md";
import CachedIcon from "@mui/icons-material/Cached";
import { FormHandles } from "@unform/core";
import { Link } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import { ItemText } from "../../../components/itemText";
import theme from "../../../theme";
import HeaderComp from "../../../components/header";
import { Container, TabelaDiv } from "./styles";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import ButtonStyled from "../../../components/Button";
import { ArrayFilters } from "../../../interfaces/filterSearch";
import { useToast } from "../../../hooks/toast";
import TicketingService from "../../../services/ticketing";
import { RechargesResponse } from "../../../interfaces/recharge";
import { RechargeTable } from "./Components/RechargeTableComponent";
import { ProfileData } from "../../MeuPerfil";
import { useAuth } from "../../../hooks/auth";
import AccountsService from "../../../services/accounts";
import Loader from "../../../components/Loader";
import ModalVerificar from "./ModalVerificar";

interface DadosFiltros {
  value: string;
  label: string;
}

const Recharge: React.FC = () => {
  const [profile, setProfile] = React.useState<ProfileData>({} as ProfileData);
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const servicesacc = { AccountsService: new AccountsService() };
  const services = { ticketingService: new TicketingService() };

  const pageSize = useMediaQuery("(max-width:1600px)");

  React.useState<DadosFiltros | null>(null);
  const [dadosdoFiltro, setDadosdoFiltro] = React.useState();
  const [dadosFiltrados, setDadosFiltrados] = React.useState();
  const [alertaOn, setAlertaOn] = React.useState<boolean>(false);
  const [alertaId, setAlertaId] = React.useState("");
  const [externalPedido, setExternalPedido] = React.useState("");
  const [pedidoVerificado, setPedidoVerificado] =
    React.useState<boolean>(false);
  const [pedidoAlterado, setPedidoAlterado] = React.useState<boolean>(false);

  const [dadosBusca, setDadosBusca] = React.useState("");

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [filters, setFilters] = useState<ArrayFilters>({
    filters: [{}],
  });
  const [allRecharges, setAllRecharges] = useState<RechargesResponse>(
    {} as RechargesResponse
  );

  const [localClean, setLocalClean] = useState<boolean>(true);

  const recargaOptions = [
    { value: "external_code", label: "Codigo Externo" },
    { value: "email", label: "Email" },
    { value: "cpf", label: "CPF" },
    { value: "payment_id", label: "ID de Pagamento" },
    { value: "tacom_order_id", label: "ID Tacom" },
    { value: "transaction_code", label: "Codigo da Transação" },
    { value: "card", label: "Cartão Transfácil" },
    { value: "authorization_code", label: "Codigo NSU" },
    { value: "amount", label: "Valor" },
  ];

  const [selectedRecarga, setSelectedRecarga] = useState(recargaOptions[0]);

  const handleRecargaChange = useCallback((selectedOption) => {
    setSelectedRecarga(selectedOption);
  }, []);

  const formRef = React.useRef<FormHandles>(null);
  const recebeId = (idalerta: any, isverificado: boolean, external: any) => {
    setPedidoVerificado(isverificado);
    setAlertaId(idalerta);
    setExternalPedido(external);
    setAlertaOn(true);
  };

  const getRechargeList = useCallback(async () => {
    try {
      setLoading(true);
      if (filters.filters.length === 1) {
        localStorage.removeItem("RecargaStatus");
        localStorage.removeItem("data");
        localStorage.removeItem("konduto");
        localStorage.removeItem("tipopagamento");
        localStorage.removeItem("duplicada");
        localStorage.removeItem("tipocartao");
        localStorage.removeItem("origem");
        localStorage.removeItem("gateway");
        localStorage.removeItem("stock_order_created");
        localStorage.removeItem("logs");
      }
      console.log(filters);
      const filtersWithoutLabel = filters.filters.map((filter) => {
        const { label, ...rest } = filter; // Remove "label"
        return rest;
      });
      console.log(filtersWithoutLabel);
      const response = await services.ticketingService.getRecarga(
        filtersWithoutLabel
      );
      setAllRecharges(response?.data);
      setTotalRows(response.data.count);
      const pages = response.data.count / 40;
      setLastPage(Math.ceil(pages));
    } catch (err: any) {
      const { ...error } = err;
      console.log(error);
      addToast({
        type: "error",
        title: "Erro ao aplicar filtro",
        description: error?.response?.data?.date[0],
      });
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [filters, dadosBusca, dadosdoFiltro, localClean]);

  const handleClearFilters = useCallback(() => {
    formRef.current?.reset();
    setFilters({
      filters: [{ page }],
    });
    window.location.reload();
  }, []);

  const handleRemoveFilter = useCallback((name: any) => {
    if (name === "status") {
      localStorage.removeItem("RecargaStatus");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("status")
          ),
        ],
      }));
    } else if (name === "konduto") {
      localStorage.removeItem("konduto");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) =>
              !filter.hasOwnProperty(
                "konduto_recommendation" || "konduto_recommendation_isnull"
              )
          ),
        ],
      }));
    } else if (name === "tipopagamento") {
      localStorage.removeItem("tipopagamento");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("payment_type")
          ),
        ],
      }));
    } else if (name === "dataFilter") {
      localStorage.removeItem("data");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) =>
              !filter.hasOwnProperty("date_before") &&
              !filter.hasOwnProperty("date_after")
          ),
        ],
      }));
    } else if (name === "tipocartao") {
      localStorage.removeItem("tipocartao");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("card_type")
          ),
        ],
      }));
    } else if (name === "origem") {
      localStorage.removeItem("origem");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("origin")
          ),
        ],
      }));
    } else if (name === "gateway") {
      localStorage.removeItem("gateway");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("gateway")
          ),
        ],
      }));
    } else if (name === "stock_order_created") {
      localStorage.removeItem("stock_order_created");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("stock_order_created")
          ),
        ],
      }));
    } else if (name === "logs") {
      localStorage.removeItem("logs");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty("needs_to_be_verified")
          ),
        ],
      }));
    } else if (name === "busca") {
      localStorage.removeItem("logs");
      setFilters((prevFilters) => ({
        filters: [
          ...prevFilters.filters.filter(
            (filter) =>
              !(
                filter.hasOwnProperty("email") ||
                filter.hasOwnProperty("payment_id") ||
                filter.hasOwnProperty("tacom_order_id") ||
                filter.hasOwnProperty("card_number") ||
                filter.hasOwnProperty("external_code") ||
                filter.hasOwnProperty("cpf") ||
                filter.hasOwnProperty("transaction_code") ||
                filter.hasOwnProperty("card") ||
                filter.hasOwnProperty("authorization_code") ||
                filter.hasOwnProperty("amount")
              )
          ),
        ],
      }));
    }
    setFilters((prevFilters) => ({
      filters: [
        ...prevFilters.filters.filter(
          (filter) => !filter.hasOwnProperty("page")
        ),
      ],
    }));
  }, []);

  const handleNumberOrderFilter = useCallback(
    (data: any) => {
      const searchFor = data.recarga;
      const updatedFilters = filters.filters.filter(
        (filter) =>
          !(
            filter.external_code ||
            filter.email ||
            filter.payment_id ||
            filter.tacom_order_id ||
            filter.card_number ||
            filter.authorization_code ||
            filter.amount ||
            filter.email ||
            filter.cpf ||
            filter.payment_id ||
            filter.tacom_order_id ||
            filter.transaction_code ||
            filter.tacom_order_id ||
            filter.card
          )
      );
      const newFilter =
        searchFor === "external_code"
          ? { external_code: data.search }
          : searchFor === "email"
          ? { email: data.search }
          : searchFor === "cpf"
          ? { cpf: data.search }
          : searchFor === "payment_id"
          ? { payment_id: data.search }
          : searchFor === "tacom_order_id"
          ? { tacom_order_id: data.search }
          : searchFor === "transaction_code"
          ? { transaction_code: data.search }
          : searchFor === "authorization_code"
          ? { authorization_code: data.search }
          : searchFor === "amount"
          ? { amount: data.search.replace(",", ".") }
          : { card_number: data.search };

      const buscaF =
        searchFor === "external_code"
          ? `Codigo Externo: ${data.search}`
          : searchFor === "email"
          ? `Email: ${data.search}`
          : searchFor === "cpf"
          ? `Cpf: ${data.search}`
          : searchFor === "payment_id"
          ? `ID de Pagamento: ${data.search}`
          : searchFor === "tacom_order_id"
          ? `ID Tacom: ${data.search}`
          : searchFor === "transaction_code"
          ? `Codigo da Transação: ${data.search}`
          : searchFor === "authorization_code"
          ? `Codigo NSU: ${data.search}`
          : searchFor === "amount"
          ? `Valor: ${data.search}`
          : `Cartão Transfácil: ${data.search}`;

      setDadosBusca(buscaF);
      updatedFilters.push(newFilter, { page: 1 });

      // setDadosBusca(updatedFilters);

      // Atualiza o estado dos filtros
      setFilters({
        filters: updatedFilters,
      });
    },
    [filters, dadosBusca]
  );

  const handleChangePerPage = useCallback(
    async (newPage: number) => {
      setPage(newPage);
      setFilters({ filters: [...filters.filters, { page: newPage }] });
    },
    [filters]
  );

  const handleFilterChange = useCallback(
    (filterKey: string, filterValue: any, filterLabel?: any) => {
      if (filterValue === "todos") {
        setFilters((prevFilters) => ({
          filters: [
            ...prevFilters.filters.filter(
              (filter) => !filter.hasOwnProperty(filterKey)
            ),
          ],
        }));
      } else {
        setFilters((prevFilters) => {
          const newFilters = prevFilters.filters.filter(
            (filter) => !filter.hasOwnProperty(filterKey)
          );
          return {
            filters: [
              ...newFilters,
              { [filterKey]: filterValue, label: filterLabel },
              { page: 1 },
            ],
          };
        });
      }
      setPage(1);
    },
    [setFilters, setPage]
  );

  const mapearFiltros = (dadosdoFiltro: any) => {
    // Verifica se o objeto existe e tem propriedades
    if (dadosdoFiltro && Object.keys(dadosdoFiltro).length > 0) {
      Object.keys(dadosdoFiltro).forEach((key) => {
        const filtro = dadosdoFiltro[key];
        if (filtro && filtro.fOrigem && filtro.value && filtro.label) {
          handleFilterChange(filtro.fOrigem, filtro.value, filtro.label);
        }
      });
    } else {
      console.log("dadosdoFiltro está vazio ou indefinido.");
    }
  };
  const filteredFilters = filters.filters.filter(
    (item) => !item.hasOwnProperty("page")
  );

  useEffect(() => {
    getRechargeList();
  }, [page, filters]);

  useEffect(() => {
    if (dadosdoFiltro) {
      mapearFiltros(dadosdoFiltro);
    }
  }, [dadosdoFiltro]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      localStorage.removeItem("RecargaStatus");

      // Exemplo: salvar dados no localStorage antes de sair
      localStorage.setItem("lastVisit", new Date().toISOString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remover o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    const getProfile = async () => {
      setProfile(null);
      try {
        const response = await servicesacc.AccountsService.getProfile();
        setProfile(response.data);
      } catch (err) {
        const { ...error }: any = err;

        addToast({
          type: "error",
          title: `${
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Erro no Servidor"
          }`,
          description: `${
            error?.response?.data?.sub_message
              ? error?.response?.data?.sub_message
              : "Tente deslogar e logar novamente, caso o erro persista entre em contato com o Administrador"
          }`,
        });
        if (err) {
          signOut();
        }
      }
    };
    getProfile();
  }, []);

  const receberDadosFiltros = (dados: any) => {
    setDadosdoFiltro(dados);
  };

  useEffect(() => {}, [dadosBusca, filters, filteredFilters, dadosBusca]);

  useEffect(() => {
    if (pedidoAlterado) {
      getRechargeList();
      setPedidoAlterado(false);
    }
  }, [pedidoAlterado]);

  const handleLocalStorage = () => {
    setLocalClean(false);
    getRechargeList();
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeaderComp
            filtroNaparece={false}
            dadosFiltros={receberDadosFiltros}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 0,
            }}
          >
            {/* {!pageSize && <NavBar />} */}
            <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
              <Box sx={{ flexGrow: 1, textAlign: "center", width: "100%" }}>
                <Container>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Form
                      ref={formRef}
                      onSubmit={handleNumberOrderFilter}
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <Input
                        id="search"
                        name="search"
                        type="text"
                        placeholder="Buscar recarga por..."
                        style={{
                          minWidth: pageSize ? "100px" : "300px",
                          borderColor: theme.palette.primary.main,
                        }}
                      >
                        <Select
                          name="recarga"
                          placeholder="Recarga"
                          defaultValue={{
                            value: "external_code",
                            label: "Codigo Externo",
                          }}
                          className="select"
                          isSearchable={false}
                          value={selectedRecarga}
                          onChange={handleRecargaChange}
                          options={recargaOptions}
                        />
                      </Input>

                      <ButtonStyled
                        color="orange"
                        padding="1"
                        type="submit"
                        className="searchButton"
                        style={{
                          marginLeft: "5px",
                          marginTop: pageSize ? "0px" : "0px",
                        }}
                      >
                        <MdSearch size={20} />
                      </ButtonStyled>
                    </Form>
                    <Box
                      sx={{
                        backgroundColor: "RGB(248, 248, 248)",
                        borderRadius: 4,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        overflowX: "auto",
                        display: "grid",
                      }}
                    >
                      <Stack
                        sx={{
                          zIndex: 0,
                        }}
                      >
                        <ListSubheader
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "10px",
                            backgroundColor: "RGB(248, 248, 248)",
                          }}
                        >
                          <Box>
                            <ItemText
                              primary="RECARGAS"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontSize: pageSize ? "30px" : "20px",
                                fontWeight: "bold",
                              }}
                            />
                          </Box>
                        </ListSubheader>
                      </Stack>
                      <Stack
                        className="Filtros"
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          textAlign: "center",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "grid",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            marginRight: "20px",
                            border:
                              filteredFilters.length > 1
                                ? `1px solid ${theme.palette.secondary.main}`
                                : "",
                            borderRadius: "10px",
                            padding: "5px",
                          }}
                        >
                          {filteredFilters.length > 1 && (
                            <ItemText
                              primary="Filtros Ativos:"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                          )}

                          {filteredFilters.length <= 1 && (
                            <ItemText
                              primary="Nenhum Filtro Ativo"
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                          )}
                          <Stack
                            sx={{
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                              direction={{ xs: "column", sm: "row" }}
                              spacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                              {filters?.filters?.map((filter: any) => {
                                const filteredFilters = filters.filters.filter(
                                  (item) => !item.hasOwnProperty("page")
                                );
                                if (filteredFilters.length > 1) {
                                  if (filter.status) {
                                    return (
                                      <ListItem
                                        key="status"
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.label &&
                                          `Status: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("status")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.date_after) {
                                    return (
                                      <ListItem
                                        key="date_after"
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.date_after &&
                                          `Data: ${filter.label}`}

                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("dataFilter")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (
                                    filter?.konduto_recommendation ||
                                    filter?.konduto_recommendation_isnull
                                  ) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {(filter?.konduto_recommendation ||
                                          filter?.konduto_recommendation_isnull) &&
                                          `RecKonduto: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("konduto")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.payment_type) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.payment_type &&
                                          `TipoPagamento: ${filter?.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("tipopagamento")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.card_type) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.card_type &&
                                          `TipoCartao: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("tipocartao")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.origin) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.origin &&
                                          `Origem: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("origem")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.stock_order_created) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.stock_order_created &&
                                          `Gateway: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter(
                                              "stock_order_created"
                                            )
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter.gateway) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter.gateway &&
                                          `Gateway: ${filter.label}`}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("gateway")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (
                                    filter?.external_code ||
                                    filter?.email ||
                                    filter?.cpf ||
                                    filter?.payment_id ||
                                    filter?.tacom_order_id ||
                                    filter?.transaction_code ||
                                    filter?.card_number ||
                                    filter?.authorization_code ||
                                    filter.amount
                                  ) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {dadosBusca}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("busca")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  } else if (filter?.needs_to_be_verified) {
                                    return (
                                      <ListItem
                                        sx={{
                                          color: theme.palette.secondary.main,
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          border: `1px solid ${theme.palette.secondary.main}`,
                                          borderRadius: "16px",
                                          marginRight: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {filter && filter?.label}
                                        <CloseIcon
                                          className="IconRemove"
                                          onClick={() =>
                                            handleRemoveFilter("logs")
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </ListItem>
                                    );
                                  }
                                }
                              })}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack className="StackHeader">
                          <Stack
                            sx={{
                              display: "grid",
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <ItemText
                              primary={`Total de Pedidos: ${totalRows}`}
                              hideSecondary
                              primarySx={{
                                color: theme.palette.secondary.main,
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            />
                            <ListItemButton
                              onClick={handleClearFilters}
                              className="BotaoLimpar"
                              sx={{
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginTop: pageSize ? "0px" : "0px",
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "16px",
                                marginRight: "10px",
                                justifyContent: "center",
                              }}
                            >
                              Limpar Filtros
                            </ListItemButton>
                          </Stack>
                          <Stack
                            sx={{
                              marginLeft: "10px",
                              justifyContent: "center",
                            }}
                          >
                            <CachedIcon
                              sx={{
                                height: "50px",
                                width: "50px",
                                cursor: "pointer",
                                color: theme.palette.primary.main,
                              }}
                              className="updatedata"
                              onClick={handleLocalStorage}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      {!loading && (
                        <TabelaDiv className="Tabela">
                          <RechargeTable
                            data={allRecharges.results}
                            clearLocalStorage={localClean}
                            columns={[
                              {
                                name: <div>Ação</div>,
                                selector: "action",
                                cell: ({
                                  id,
                                  needs_to_be_verified,
                                  external_code,
                                }: any) => (
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <Link
                                      to={`/recargas/${id}`}
                                      className="custom-link"
                                    >
                                      <Tooltip title="Visualizar Pedido">
                                        <VisibilityIcon className="iconView" />
                                      </Tooltip>
                                    </Link>
                                    {/* {needs_to_be_verified === true ? (
                                      <Tooltip
                                        title="Pedido Verificado!"
                                        onClick={() =>
                                          recebeId(
                                            id,
                                            needs_to_be_verified,
                                            external_code
                                          )
                                        }
                                      >
                                        <CheckBoxIcon className="iconCheck" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Verificar Pedido?"
                                        onClick={() =>
                                          recebeId(
                                            id,
                                            needs_to_be_verified,
                                            external_code
                                          )
                                        }
                                      >
                                        <CheckBoxOutlineBlankIcon className="iconCheck" />
                                      </Tooltip>
                                    )} */}
                                  </Stack>
                                ),
                              },
                              {
                                name: <div>ID</div>,
                                selector: "id",
                                cell: ({ id }: any) => (
                                  <div className="specifitcell">{id}</div>
                                ),
                              },
                              {
                                name: <div>Codigo externo</div>,
                                selector: "external_code",
                                cell: ({ external_code }: any) => (
                                  <div className="specifitcell">
                                    {external_code}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Origem</div>,
                                selector: "origin",
                                cell: ({ origin }: any) => (
                                  <div className="specifitcell">{origin}</div>
                                ),
                              },
                              {
                                name: <div>User</div>,
                                selector: "user",
                                cell: ({ user_email }: any) => (
                                  <div className="specifitcell">
                                    {user_email}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Status</div>,
                                selector: "status",
                                cell: ({ status_display }: any) => (
                                  <div className="specifitcellS">
                                    {status_display}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Recomendação Konduto</div>,
                                selector: "konduto_recommendation",
                                cell: ({ konduto_recommendation }: any) => (
                                  <div className="specifitcell">
                                    {konduto_recommendation}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Codigo da Transação</div>,
                                selector: "transaction_code",
                                cell: ({ transaction_code }: any) => (
                                  <div className="specifitcell">
                                    {transaction_code}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Codigo NSU</div>,
                                selector: "authorization_code",
                                cell: ({ authorization_code }: any) => (
                                  <div className="specifitcell">
                                    {authorization_code}
                                  </div>
                                ),
                              },
                              {
                                name: <div>ID de Pagamento</div>,
                                selector: "payment_id",
                                cell: ({ payment_id }: any) => (
                                  <div className="specifitcell">
                                    {payment_id}
                                  </div>
                                ),
                              },
                              {
                                name: <div>ID Tacom</div>,
                                selector: "tacom_order_id",
                                cell: ({ tacom_order_id }: any) => (
                                  <div className="specifitcell">
                                    {tacom_order_id}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Origem Pagamento</div>,
                                selector: "gateway_display",
                                cell: ({ gateway_display }: any) => (
                                  <div className="specifitcell">
                                    {gateway_display}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Cartão Transfácil</div>,
                                selector: "card",
                                cell: ({ card_number }: any) => (
                                  <div className="specifitcell">
                                    {card_number}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Form of Payment</div>,
                                selector: "form_of_payment",
                                cell: ({ payment_type_display }: any) => (
                                  <div className="specifitcell">
                                    {payment_type_display}
                                  </div>
                                ),
                              },
                              {
                                name: <div>Valor</div>,
                                selector: "amount",
                                cell: ({ amount }: any) => (
                                  <div className="specifitcell">{`R$ ${formatCurrency(
                                    Number(amount)
                                  )}`}</div>
                                ),
                              },
                            ]}
                          />
                        </TabelaDiv>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 2,
                          marginBottom: 2,
                          fontWeight: "bold",
                        }}
                      >
                        <Button
                          disabled={allRecharges?.previous === null}
                          onClick={() => handleChangePerPage(1)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Primeira Página
                        </Button>
                        <Button
                          disabled={allRecharges?.previous === null}
                          onClick={() => handleChangePerPage(page - 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Anterior
                        </Button>
                        <Box
                          sx={{ margin: 2 }}
                        >{`Página ${page} de ${lastPage}`}</Box>
                        <Button
                          disabled={allRecharges?.next === null}
                          onClick={() => handleChangePerPage(page + 1)}
                          sx={{
                            backgroundColor: theme.palette.secondary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Próxima
                        </Button>
                        <Button
                          disabled={allRecharges?.next === null}
                          onClick={() => handleChangePerPage(lastPage)}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            margin: 1,
                            color: "white",
                          }}
                        >
                          Última Página
                        </Button>
                      </Box>
                    </Box>
                  </List>
                </Container>
              </Box>
            </Box>
          </Box>
          {alertaOn && (
            <ModalVerificar
              open={alertaOn}
              handleClose={() => setAlertaOn(false)}
              id={alertaId}
              verificado={pedidoVerificado}
              external={externalPedido}
              pedidoAlterado={setPedidoAlterado}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Recharge;
