/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import ListItemButton from "@mui/material/ListItemButton";
import { Container } from "./styles";
import Button from "../../../../../components/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "#472b74",
  borderRadius: "12px",
};

interface CancelInterface {
  open?: boolean;
  handleClose?: () => void;
  show?: boolean;
  dadopedido?: any;
  event?: any;
}

const ModalCancelar: React.FC<CancelInterface> = ({
  show,
  handleClose,
  open,
  dadopedido,
  event,
}) => {
  function opencancel() {
    event(true);
  }

  return (
    <Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "10px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="title"
            sx={{
              fontWeight: "bold",
            }}
          >
            !ATENÇÃO: Deseja Cancelar a carga deste Pedido?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2 }}
            className="text"
          >
            Confirmando você ira cancelar a carga do pedido:{" "}
            <strong style={{ color: "#dc582a" }}>{dadopedido}</strong>
          </Typography>
          <Button
            type="button"
            color="orange"
            padding="4"
            className="button button-transparent"
            rounded
            onClick={opencancel}
          >
            Cancelar Carga
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ModalCancelar;
