/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DataTable from "react-data-table-component";
import { Container } from "./styles";

interface ITable {
  columns: any[];
  data: any[];
  title?: any;
  clearLocalStorage?: boolean;
}

const RechargeTable: React.FC<ITable> = ({
  columns,
  data,
  title,
  clearLocalStorage,
}) => {
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.selector)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleColumnVisibility = (column: string) => {
    setVisibleColumns((prev) =>
      prev.includes(column)
        ? prev.filter((col) => col !== column)
        : [...prev, column]
    );
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const customColumns = columns.filter((col) =>
    visibleColumns.includes(col.selector)
  );

  useEffect(() => {
    if (clearLocalStorage) {
      localStorage.removeItem("visibleColumns");
    }
  }, [clearLocalStorage]);

  useEffect(() => {
    const storedColumns = localStorage.getItem("visibleColumns");
    if (storedColumns) {
      setVisibleColumns(JSON.parse(storedColumns));
    } else {
      localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
    }
  }, [columns]); // Reexecuta ao mudar as colunas padrão

  // Atualiza o localStorage sempre que o estado visibleColumns mudar
  useEffect(() => {
    localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  return (
    <Container>
      {/* Botão para abrir o modal */}
      <div className="columns">
        <Button
          startIcon={<SettingsIcon />}
          variant="outlined"
          onClick={toggleModal}
          className="buttoncolum"
        >
          Configurar Colunas
        </Button>
      </div>

      {/* Modal */}
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            color: "var(--purple)",
            fontWeight: "bold",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Configuração de Colunas
          </Typography>
          {columns.map((col) => (
            <FormControlLabel
              key={col.selector}
              control={
                <Checkbox
                  checked={visibleColumns.includes(col.selector)}
                  onChange={() => toggleColumnVisibility(col.selector)}
                  style={{ color: "var(--orange)" }}
                />
              }
              label={col.name.props?.children || col.name}
            />
          ))}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={toggleModal}
              style={{ backgroundColor: "var(--purple)" }}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
      <div
        style={{
          overflowX: "auto",
          WebkitOverflowScrolling: "touch", // Para melhorar o scroll em iOS
          width: "100%",
        }}
      >
        <DataTable columns={customColumns} data={data} title={title} />
      </div>
    </Container>
  );
};

export { RechargeTable };
