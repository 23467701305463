/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
  padding: string;
  rounded?: boolean;
  textBlack?: boolean;
}

const ButtonStyled: React.FC<ButtonProps> = ({
  color,
  padding,
  rounded,
  textBlack,
  children,
  ...rest
}) => (
  <Container
    color={color}
    padding={padding}
    rounded={rounded}
    type="button"
    textBlack={textBlack}
    {...rest}
  >
    {children}
  </Container>
);

export default ButtonStyled;
